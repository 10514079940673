import {Carte} from "../../../Bibliotheque/Composants/Presentation/Carte";

const CarteFactory = (parameters) => {

    const {lat,lon,key} = parameters;
    const position = [lat,lon];
    return (
        <Carte key={key} position={position} />
    );

}

export {CarteFactory}