// Génère une bannière, un peu un bridge mais moins complexe, un peu un adapteur mais plus spécialisé
import {IMAGE_URL} from "../../../../Config";
import {Banniere} from "./Banniere";
import {useTranslation} from "react-i18next";

const BannerFactory = (parameters) => {

    let {titre,bg_image} = parameters;
    const {projection_data, key} = parameters;

    const { i18n } = useTranslation();

    if (titre==='_titre_'){
        titre = projection_data?.translatedNom[i18n.language] ?? projection_data?.translatedNom?.fr;
    }

    // on fait un match de regex pour extraire l'indice avec rappel sur 0
    if (bg_image.startsWith('_gallery_')){
        let matches = bg_image.match(new RegExp("_gallery_\\[([0-9]+)\\]"));
        let number = matches ? matches[1] : undefined;
        let image_path = projection_data?.gallery[number]?.path ?? "undefined";
        bg_image = `${IMAGE_URL}/${image_path}`;
    }
    return (
        <Banniere titre={titre} bg_image={bg_image} key={key}/>
    );
}

export { BannerFactory }