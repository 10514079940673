import {DateComponent} from "./DateComponent.js";
import {useTranslation} from "react-i18next";

const DateFactory = (parameters) => {

    const {projection_data, key} = parameters;

    // extract date
    const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    const date_debut_string = projection_data.entree_programmes[0].date_debut.match(regex)[0];
    const date_fin_string = projection_data.entree_programmes[0].date_fin.match(regex)[0];
    const date_debut = new Date(date_debut_string);
    const date_fin = new Date(date_fin_string);

    return (
        <DateComponent key={key} date_debut={date_debut} date_fin={date_fin} />
    );
}

export {DateFactory};