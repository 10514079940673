import LoadingComponent from "../../../../Components/Elements/loading.component";
import {Suspense} from "react";
import {Await, useLoaderData} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AfficheurSectionEvenementAgenda} from "./AfficheurSectionEvenementAgenda";

const EvenementAgenda = () => {

    const { t, i18n } = useTranslation();
    const { data } = useLoaderData();

    const oneLineClass = "col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4";

    const displayNom = (evenement_agenda) => {
        return evenement_agenda.translatedNom[i18n.language]
    }

    const navigateToMySpace = () => {
        window.location.href='/myspace';
    }

    return (
        <Suspense fallback={<LoadingComponent/>}>
            <Await resolve={data}>
                {
                    !data || !data.length || data.length !== 1 ?
                        navigateToMySpace() :
                        (<div
                            className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 max-w-[2048px] mx-auto my-12 px-6 w-full">
                            <h2 className={`${oneLineClass} text-2xl font-extrabold`}>
                                {displayNom(data[0])}
                            </h2>
                            <AfficheurSectionEvenementAgenda data={data}/>
                        </div>)
                }
            </Await>
        </Suspense>
    );
}

export {EvenementAgenda};