import {StatusEvaluation} from "./SatusEvaluation";
import {NomEvaluable} from "./NomEvaluable";
import {TimeService} from "../../../Services/time.service";

const PresentationEvaluable = ({splash_data, category_name, questionnaire}) => {
    // bizarre le grid-cols-2 devrait faire une grille de deux colonnes,
    // et il fait une grille de 3 colonnes

    const date_debut_evaluation = (new TimeService()).convertDateTimeStringToJSDateTimeObjet(splash_data.dateEvaluation);
    const date_fin_questionnaire = (new TimeService()).convertDateTimeStringToJSDateTimeObjet(questionnaire.date_fermeture);

    return (
        <div className={`w-full max-w-3xl grid justify-start grid-cols-2 m-auto`}>
            <NomEvaluable className={`col-start-1 col-end-2 `} nom_evaluable={splash_data.nom}/>
            <StatusEvaluation className={`col-start-2 col-end-3 justify-self-end`}
                              status={splash_data.evaluationStatus} date_debut_evaluation={date_debut_evaluation} date_fin_questionnaire={date_fin_questionnaire}/>
            <div className={`col-start-1 col-end-2 text-base-content font-semibold text-sm`}>{category_name}</div>
        </div>
    );
};

export {PresentationEvaluable}