import { Competiteur } from './Competiteur';
import {AfficheurEvaluationsPourEvenementAgenda} from "./AfficheurEvaluationsPourEvenementAgenda";
import {AfficheurEvenementAgendaBasique} from "./AfficheurEvenementAgendaBasique";
const AfficheurSectionEvenementAgenda = ({data}) => {

    // on classe par compétiteur
    let evenement_agenda = data[0];

    return evenement_agenda?.evaluations?.length ?
        (<AfficheurEvaluationsPourEvenementAgenda evenement_agenda={evenement_agenda} />) :
        (<AfficheurEvenementAgendaBasique evenement_agenda={evenement_agenda} />);
}

export { AfficheurSectionEvenementAgenda }