import {ComposantMapping, getComposantFromMapping} from './Mapping/ComposantMapping';

const DescriptionParser = (projection, description, fallback_parser, overridenMapping) => {

    let composants = [];
    try{
        composants = description ? JSON.parse(description) : [];
    }catch( e ) {
        // parsing error:
    }


    let composantsAsArray = [];

    composants.map( (item, index) => {
        for (const composant in item){
            const compos = getComposantFromMapping(composant,{...item[composant]}, fallback_parser, projection, index, overridenMapping);
            // composantsAsArray.push( (ComposantMapping[composant]({...item[composant], animation_data: animation_data, key:index})));
            composantsAsArray.push(compos);
        }
    });

    return composantsAsArray;
}

export {DescriptionParser};