import axios from "axios";
import { API_URL } from "../Config";

import {
	axiosFetch,
	interceptor_callbacks,
	interceptor_references,
} from "./axiosInstance";

// On logue sans intercepteurs, alors on part d'une instance fraîche d'axios

const auth_login = (email, password)=>{
	// logout
	return new Promise( (resolve,reject) => {
		axios.post(`${API_URL}/login`,
			{email, password})
			.then(response => {
				response.data?.token && localStorage.setItem("user_token", response.data.token);
				response.data?.refresh_token && localStorage.setItem("refresh_token", response.data.refresh_token);
				resolve('ok');
			})
			.catch(error => {
				console.log(error);
				reject(error)
			});
	});
};

const auth_refreshToken = async () => {
	// peut-être le refresh_token n'est récupéré du localstorage mais depuis la mémoire
	// puisque la fonction a déjà été exécutée ??
	let refresh_token = localStorage.getItem('refresh_token');
	try{
		if ( ! refresh_token ){
			throw new Error("pas de jeton de refresh");
		}

		// virer l'intercepteur de requête lors du refresh token
		axiosFetch.interceptors.request.eject(interceptor_references.add_auth_header);
		const response = await axiosFetch.post("token/refresh",{
			refresh_token: `${refresh_token}`
		});

		localStorage.setItem("user_token", response.data?.token);
		localStorage.setItem("refresh_token", response.data?.refresh_token);

		// reconstruire l'intercepteur, conserver la référence pour pouvoir l'éjecter par la suite
		interceptor_references.add_auth_header = axiosFetch.interceptors.request.use(
			interceptor_callbacks.request_interceptor__add_auth_header_to_request,
			interceptor_callbacks.request_interceptor__add_auth_header_to_request__fallback
		);

	}catch(e){
		console.log("Erreur lors du refresh token " + e.message);
	}
};

export { auth_login, auth_refreshToken };
