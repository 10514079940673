import {Await, useLoaderData} from "react-router-dom";
import {Suspense, useState} from "react";
import LoadingComponent from "../../Elements/loading.component";
import {PresentationEvaluable} from "../../../Bibliotheque/Composants/Metier/PresentationEvaluable";
import {SplashImage} from "../../../Bibliotheque/Composants/Metier/SplashImage";
import {CoordonneesEvaluable} from "../../../Bibliotheque/Composants/Metier/CoordonneesEvaluable";
import {ControleursEvaluation} from "../../../Bibliotheque/Composants/Metier/ControleurEvaluation";
import {ModaleEvaluation} from "../../../Bibliotheque/Composants/Metier/ModaleEvaluation";
import {NomEvaluable} from "../../../Bibliotheque/Composants/Metier/NomEvaluable";
import {StatusEvaluation} from "../../../Bibliotheque/Composants/Metier/SatusEvaluation";

const StartEvaluationFactory = (props) => {

    const {key} = props;
    const loaded = useLoaderData();

    const [affichageEvaluation, setAffichageEvaluation] = useState(false);

    // en mode défensif si le composant est utilisé en dehors d'un
    // suspense pour éviter de casser

    const toggleAffichageEvaluation = (event) => {
        setAffichageEvaluation(!affichageEvaluation);
    }

    const nom_competiteur = loaded?.data?.splash?.nom;

    return (
        <Suspense fallback={<LoadingComponent/>} key={key}>
            <Await resolve={loaded.data}>
                { loaded?.data?.projection?.gallery[0]?.path ? (<SplashImage image_path={loaded.data.projection.gallery[0].path}/>) : (<></>) }
                <div className={`w-full px-2 lg:px-0 flex flex-col justify-start space-y-10`}>
                    <PresentationEvaluable splash_data={loaded.data.splash}/>
                    <CoordonneesEvaluable nom={nom_competiteur} lieu={loaded.data.projection.lieux[0]} telephone={loaded.data.splash.numeroTel}/>
                    <ControleursEvaluation status_evaluation={loaded.data.splash.evaluationStatus} clickHandle={toggleAffichageEvaluation}/>
                    <ModaleEvaluation affichageEvaluation={affichageEvaluation} handleClose={toggleAffichageEvaluation}/>
                </div>
            </Await>
        </Suspense>
    );
}

export {StartEvaluationFactory}