// import {IMAGE_URL} from "../../../Config";
import {IMAGE_URL} from "../../../../Config";
import {useTranslation} from "react-i18next";

const PuceEvaluation = ({evaluation, titre}) => {

    const {i18n} = useTranslation();
    const displayHeure = (sDatetime,timzone) =>{

        const date_heure =  sDatetime.split('T');
        const heure_minute_secondes_tz = date_heure[1].split(':');
        const string_to_return = `${heure_minute_secondes_tz[0]}:${heure_minute_secondes_tz[1]}`;

        return string_to_return;
    }

    const evaluation_path = evaluation ? `/questionnaire/${evaluation?.questionnaire?.id}/competiteur/${evaluation?.competiteur.id}` : '#';

    return (
        <a href={evaluation_path}
           className={`flex justify-start items-start border border-accent rounded-md w-full col-span-1 h-[107px] bg-base-300 text-base-content`}
        >
            <figure className={`h-[100%] w-[100px] bg-neutral`}>
                <img src={`${IMAGE_URL}/${evaluation?.image?.path ?? "#"}`}
                     className={`object-cover w-full h-full`}/>
            </figure>
            <div className={`flex flex-col p-2 justify-around items-start h-full`}>
                    <span
                        className={`text-base font-normal flex-grow`}>{titre}</span>
                <span className={`w-full flex flex-row justify-between items-end gap-2`}>
                                    {/*<span className={`text-sm`}>{evaluation?.lieu?.nom ?? "nom lieu"}</span>*/}
                                    <span
                                        className={`text-sm`}>{displayHeure(evaluation.date_de_visite, evaluation.timezone)}</span>
                                </span>
            </div>
        </a>
    )
        ;
}

export {PuceEvaluation};