import {Link, useAsyncValue} from "react-router-dom";
import { useReducer } from "react";

import { Section } from "./Section";
import { Pager } from './Pager';
import {useOnlineState} from "../../../Providers/onlinestatequeryable.provider";
import {build_evaluationDTO_from_questionnaire_state} from "../../../DTO/evaluation_dto";
import {useTranslation} from "react-i18next";
import toast from 'react-hot-toast';
// import the theme directory from the config file
import * as successAnimationData from '../../../../src/Theme/scrprod/Assets/Image/lotties/success_animation.json';
import Lottie from "react-lottie";
import LoadingComponent from "../../Elements/loading.component";
import {useSyncToServer} from "../../../Providers/SynchronizationGlobalProvider";
import {QuestionnaireStateFactory} from "../../../Services/DTOStack";
import {TimeService} from "../../../Services/time.service";



const questionnaireStateReducer = (state, action) => {

    const { payload, type } = action;
    let next_state = {...state};
    let questions = [];
    let nouvelle_valeur = null;
    let section_number = null;
    let question_number  = null;
    let current_section_number = null;
    let section_is_valide = true;
    let current_question_is_valide = false;

    switch( type ){
        case 'setValeur' :

            nouvelle_valeur = payload.valeur;
            section_number = payload.section_number;
            question_number = payload.question_number;

            // on reset le displayInvalide des questions de la section
            questions = state.questionnaire_data.sections[section_number].questions;
            for (let i=0; i < questions.length; i++){
                next_state.questionnaire_data.sections[section_number].questions[i].presentation.displayInvalide = false;
            }

            const nouvelle_valeur_validation =
                state.questionnaire_data.sections[section_number].questions[question_number].requis ?
                    nouvelle_valeur !== '' : true;

            next_state.questionnaire_data.sections[section_number].questions[question_number].presentation.valide = nouvelle_valeur_validation;
            next_state.questionnaire_data.sections[section_number].questions[question_number].presentation.valeur = nouvelle_valeur;

            return next_state;

        case 'display_section_invalide':

            // valuer unused [ maybe pour toggle ]
            nouvelle_valeur = payload.valeur;
            section_number = payload.sectionNumber;
            // display invalide pour toute la section
            questions = next_state.questionnaire_data.sections[section_number].questions;
            for ( let i=0; i<questions.length; i++){
                next_state.questionnaire_data.sections[section_number].questions[i].presentation.displayInvalide = !questions[i].presentation.valide;
            }

            return next_state;

        case 'go_to_next_section':
            // on n'avance que si la section est valide
            current_section_number = payload.current_section_number;
            questions = state.questionnaire_data.sections[current_section_number].questions;
            for ( let i=0; i<questions.length; i++) {
                current_question_is_valide =
                    (!state.questionnaire_data.sections[current_section_number].questions[i].presentation.requis) ||
                    (
                        state.questionnaire_data.sections[current_section_number].questions[i].presentation.requis &&
                        0 !== state.questionnaire_data.sections[current_section_number].questions[i].presentation.valeur.toString().trim().length
                    )

                next_state.questionnaire_data.sections[current_section_number].questions[i].presentation.valide = current_question_is_valide;
                section_is_valide = section_is_valide &&
                    current_question_is_valide

            }

            if ( ! section_is_valide ){
                for ( let i=0; i<questions.length; i++){
                    next_state.questionnaire_data.sections[current_section_number].questions[i].presentation.displayInvalide =
                        ! next_state.questionnaire_data.sections[current_section_number].questions[i].presentation.valide;
                }

                return next_state;
            }
            // assumé on ne vérifie pas si la section dépasse le tableau

            // on tente une synchronisation avec le serveur

            // next_state.current_section = state.current_section+1;
            //

            return next_state;

        case 'compute_invalide_state':
            current_section_number = payload.current_section_number;
            questions = state.questionnaire_data.sections[current_section_number].questions;
            for ( let i=0; i<questions.length; i++) {
                current_question_is_valide =
                    (!state.questionnaire_data.sections[current_section_number].questions[i].presentation.requis) ||
                    (
                        state.questionnaire_data.sections[current_section_number].questions[i].presentation.requis &&
                        0 !== state.questionnaire_data.sections[current_section_number].questions[i].presentation.valeur.toString().trim().length
                    )

                next_state.questionnaire_data.sections[current_section_number].questions[i].presentation.valide = current_question_is_valide;
                section_is_valide = section_is_valide &&
                    current_question_is_valide

            }

            if ( ! section_is_valide ){
                for ( let i=0; i<questions.length; i++){
                    next_state.questionnaire_data.sections[current_section_number].questions[i].presentation.displayInvalide =
                        ! next_state.questionnaire_data.sections[current_section_number].questions[i].presentation.valide;
                }

            }
            return next_state;

        case 'set_section_number':
            nouvelle_valeur = payload.valeur;
            next_state.current_section = nouvelle_valeur;
            return next_state;

        case 'section_loaded':
            next_state.current_section_is_loading = false;
            return next_state;

        case 'set_current_section_is_loading':
            next_state.current_section_is_loading = payload.valeur;
            return next_state;

        case 'set_synchronize_flag':
            next_state.synchronize_with_server = payload.valeur;
            next_state.current_section_is_loading = payload.valeur;
            next_state.payload_to_send = payload.payload_to_send;
            return next_state;

        default:
            return next_state;
    }
}

const is_current_section_valid = (current_section, questionnaire_state) => {
    const questions = questionnaire_state.questionnaire_data.sections[current_section].questions;
    let section_is_valide = true;
    let current_question_is_valide = true;

    for ( let i=0; i<questions.length; i++) {
        current_question_is_valide =
            (!questionnaire_state.questionnaire_data.sections[current_section].questions[i].presentation.requis) ||
            (
                questionnaire_state.questionnaire_data.sections[current_section].questions[i].presentation.requis &&
                0 !== questionnaire_state.questionnaire_data.sections[current_section].questions[i].presentation.valeur.toString().trim().length
            )

        section_is_valide = section_is_valide &&
            current_question_is_valide
    }

    return section_is_valide;
}

const resolveDefaultResponse = (component_name) => {
    const defaultResponseMapping = {
        QuestionTexte: '',
        QuestionRange: 1,
    }

    return defaultResponseMapping[component_name];
}

const enrich_questionnnaire_data_with_presentation_data = (questionnaire_data, current_section = -1) =>
{

    let questionnaire_data_to_return = questionnaire_data;

    // Ajoute les données de présentation aux données du serveur
    let sections = questionnaire_data?.sections?? [{questions: []}];

    for ( let i = 0; i<sections.length; i++){

        let questions = sections[i]?.questions ?? [{reponses: []}];


        for ( let j = 0; j<questions.length; j++){
            let valeur_reponse = questionnaire_data.sections[i].questions[j].reponses ? questionnaire_data.sections[i].questions[j].reponses[0]?.valeur : null;
            valeur_reponse = valeur_reponse ?? resolveDefaultResponse(questionnaire_data.sections[i].questions[j].representation.component);

            questionnaire_data_to_return.sections[i].questions[j] = {
                ...questionnaire_data.sections[i].questions[j],
                presentation: {
                    requis : questionnaire_data.sections[i].questions[j].requis,
                    // les données ne sont pas valides mais elles ne sont pas invalides à l'initialisation
                    valide : true,
                    displayInvalide: false,
                    // valide: questionnaire_data.sections[i].questions[j].requis ? valeur_reponse !== '' : true,
                    valeur: valeur_reponse,
                }
            };
        }
    }

    // console.log(questionnaire_data);
    return questionnaire_data_to_return;

}

const initialQuestionnaireState = ({questionnaire_data, last_questionnaire_dtos}) => {

    let initial_state = {
        questionnaire_data: {
            sendBacks: {}
        },
        current_section_is_loading: false,
        // numéro de la section qui s'affiche
        current_section: 0,
        // flag de synchro suivi par l'effet questionnaire
        synchronize_with_server: false,
    };

    initial_state.questionnaire_data = enrich_questionnnaire_data_with_presentation_data(questionnaire_data);

    // puis on modifie les données par les données à jour de la présentation

    if ( last_questionnaire_dtos && Object.keys(last_questionnaire_dtos).length > 0 ) {
        // on reconstruit le questionnaire depuis toutes les sections valides enregistrées

        let enriched_state = (new QuestionnaireStateFactory()).buildFromQuestionnaireDTOs(last_questionnaire_dtos, initial_state);

        initial_state.questionnaire_data = { ...initial_state.questionnaire_data, ...enriched_state.questionnaire_data };

    }

    return initial_state;
}

const SectionFinale = ({date_cloture_questionnaire}) => {

    const { t, i18n } = useTranslation();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const getDateCloture = (date_cloture_questionnaire, lang) => {
        let timeservice = new TimeService();
        let date = timeservice.convertDateTimeStringToJSDateTimeObjet(date_cloture_questionnaire);
        return `${timeservice.convertDateToLocaleDateString(date, lang)}, ${timeservice.convertDateToLocaleDateTimeString(date, lang)}`;

    }

    let localized_date_cloture = getDateCloture(date_cloture_questionnaire, i18n.language);

    return (
        <section className={` flex flex-col space-y-8 my-8 items-center`}>
            <Lottie options={defaultOptions} height={200} width={200}/>
            <div className={`text-2xl font-bold`}>{t('Questionnaire.remerciementsevaluation')}</div>
            <Link className={`btn btn-primary`} to={`/myspace`}>{t('UI.Boutons.retourmyspace')}</Link>
            <span className={`text-md text-primary-content`}>
                {t('Questionnaire.retoursurevaluation', { date_cloture_questionnaire: localized_date_cloture})}
            </span>
        </section>
    );
}

const Questionnaire = (props) => {

    let questionnaire_data = useAsyncValue();
    const { isOnline } = useOnlineState();

    const {questionMapping} = props;

    // check if there is a more fresh data from the dto stack.

    // const {pushQuestionnaireState, findQuestionnaireState} = useSynchronizationStackProvider();
    const {pushDTO, setSyncFlag, findQuestionnaireDTOs} = useSyncToServer();

    let last_questionnaire_dtos = findQuestionnaireDTOs(questionnaire_data?.id, questionnaire_data?.sendBacks?.query__evaluable_id);

    const [ questionnaireState, dispatchQuestionnaireState ] = useReducer(questionnaireStateReducer,
        {questionnaire_data: questionnaire_data,
        last_questionnaire_dtos: last_questionnaire_dtos}, initialQuestionnaireState);

    const { t, i18n } = useTranslation();

    const n_sections = questionnaire_data?.sections?.length ?? 1;

    const {current_section} = questionnaireState;
    const setNextSection = () => {

        // si la section est invalide, on calcule la présentation et on change l'état
        if ( ! is_current_section_valid(current_section, questionnaireState) ){
            toast.error(t('UI.Toasts.sectioninvalide'));
            dispatchQuestionnaireState({
                type: 'compute_invalide_state',
                payload: {
                    current_section_number: current_section
                }
            });
            return;
        }

        // interdit de dépasser la dernière section

        // avec ou sans toast ?
        // if ( n_sections === current_section+1){
        //     is_current_section_valid(current_section, questionnaireState) &&
        //         toast.success(t('UI.Toasts.remerciement'));
        // }

        if ( (n_sections) <= current_section ){
            return ;
        }

        // on construit l'objet de transfert d'état
        let questionnaire_dto = build_evaluationDTO_from_questionnaire_state(questionnaireState);
        pushDTO(questionnaire_dto);

        if (isOnline){
            setSyncFlag(true);
        }

        dispatchQuestionnaireState({type: 'set_section_number', payload: {valeur: current_section+1}});
    }

    const setPrevSection = () => {
        if ( 0 === current_section ){
            return ;
        }

        dispatchQuestionnaireState({type: 'set_section_number', payload: {valeur: current_section-1}})
        // setSectionNumber(sectionNumber-1);
    }

    const handleNextSectionLabel = () => {
        if ( n_sections === (current_section+1) ){
            return t('UI.Boutons.fineva');
        }
        return t('UI.Boutons.suivant');
    }

    return (
        <div className={`flex flex-col grow pb-4`}>
            {
                questionnaireState.current_section_is_loading ?
                    (<LoadingComponent />) :
                    (
                        n_sections === questionnaireState.current_section ?
                            (<SectionFinale date_cloture_questionnaire={questionnaire_data.date_fermeture}/>) :
                            (
                                <>
                                    <Section sectionNumber={current_section} dispatcher={dispatchQuestionnaireState} questionnaireState={questionnaireState} questionMapping={questionMapping} />
                                    <Pager nextHandle={setNextSection} prevHandle={setPrevSection} nextSectionLabel={handleNextSectionLabel} />
                                </>
                            )
                    )
            }
        </div>
    );
}

export { Questionnaire };

