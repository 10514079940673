import {useTranslation} from "react-i18next";

const QuestionStarRateRange = ({questionNumber, representation, presentation, dispatcher, sectionNumber, ...component_data}) => {

    const { min, max } = representation;
    const  length = max - min +1;

    // const valeur = presentation_data.valeur;
    const valeur = presentation?.valeur;

    const { t, i18n } = useTranslation();

    const handle_intitule = (component_data) => {
        const translatedIntitule = component_data?.translatedIntitule ?? {fr: '__intitule fr__', en: '__intitule en__'};
        const lang = i18n.language;
        return translatedIntitule[lang];
    }

    const handleChangeValue = (event) => {
        dispatcher({
            type:'setValeur',
            payload: {
                section_number: sectionNumber,
                question_number: questionNumber,
                valeur: event.target.value
            }});
    }

    const display_stars = (min,max,valeur) => {
        let input_array = [];
        valeur = parseInt(valeur);
        for (let i=min; i<=max; i++){
            input_array.push(
                (<input onChange={handleChangeValue} type={`radio`}
                        key={i}
                        name={`q-${questionNumber}`} className={`mask mask-star bg-accent`}
                        value={i}
                        checked={valeur===i}/>)
            );
        }
        return input_array;
    }

    return (
        <div className={`flex flex-col text-normal my-2 rounded-xl`}>
            <div className={`font-medium`}><span>{handle_intitule(component_data)}</span> {component_data.requis ? (<span className={`text-error`}>*</span>) : ''} </div>
            <div className={`rating space-x-2`}>
                {
                    display_stars(min,max,valeur).map( input => input)
                }
            </div>
        </div>
    );
};

export { QuestionStarRateRange };