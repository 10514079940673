import {MapContainer, Marker, TileLayer} from "react-leaflet";

const Carte = ({position}) => {
    return (
        <div className="h-w-full min-h-[500px] w-full" >
            <div className={`map-container max-w-3xl max-h-[500px] m-auto rounded-2xl overflow-hidden`}>
                <MapContainer  center={position} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position} ></Marker>
                </MapContainer>
            </div>
        </div>
    );
}

export {Carte};