const Image = ({src, is_portrait}) => {

    let class_name = `w-full  flex justify-start rounded-2xl overflow-hidden 
        ${is_portrait ? 'max-w-[256px]' : 'max-w-3xl'}
    `;

    return (
        <figure className={class_name}>
            <img className={`object-cover h-full w-full`} src={src} />
        </figure>
    );
}

export {Image}