
const Titre = ({text, type}) => {
    const baseClassName = "w-full max-w-3xl flex justify-start font-bold";

    if (!text){
        return (<></>);
    }

    switch( type ){
        case 'h1':
            return (<h1 className={`${baseClassName} text-3xl`}>{text}</h1>);
        case 'h2':
            return (<h2 className={`${baseClassName} text-2xl`}>{text}</h2>);
        case 'h3':
            return (<h3 className={`${baseClassName} text-xl`}>{text}</h3>);
        case 'h4':
            return (<h4 className={`${baseClassName} text-lg`}>{text}</h4>);
        default:
            return (<p className={`${baseClassName} text-md`}>{text}</p>)
    }

    // if(!text) {return(<></>);}
    // return (<h1 className={`w-full max-w-3xl flex justify-start text-3xl font-bold`} key={key}>{text}</h1>);
};

export { Titre }