// retire la donnée de connexion,
// toast : "déconnecté"
// redirect en homepage


import {useNavigate} from "react-router-dom";
import {removeIdentifiedUserToken, removeIdentifierRefreshToken} from "../../../../Services/storage.service";
import toast from "react-hot-toast";
import {useEffect} from "react";

const DeconnexionScreen = () => {
    const navigate = useNavigate();

    useEffect( () => {
        toast('Déconnecté');
        navigate('/');
    }, []);

    removeIdentifierRefreshToken();
    removeIdentifiedUserToken();

    return (
        <></>
    )
};

export { DeconnexionScreen };