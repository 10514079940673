import {useTranslation} from "react-i18next";

const QuestionSlideRange = ({questionNumber, representation, presentation, dispatcher, sectionNumber, ...component_data}) => {

    const { min, max } = representation;
    const  length = max - min +1;

    // const valeur = presentation_data.valeur;
    const valeur = presentation?.valeur;

    const { t, i18n } = useTranslation();

    const handle_intitule = (component_data) => {
        const translatedIntitule = component_data?.translatedIntitule ?? {fr: '__intitule fr__', en: '__intitule en__'};
        const lang = i18n.language;
        return translatedIntitule[lang];
    }

    const handleChangeValue = (event) => {
        dispatcher({
            type:'setValeur',
            payload: {
                section_number: sectionNumber,
                question_number: questionNumber,
                valeur: event.target.value
            }});
    }

    const display_range = (min, max, valeur) => {
        return (
            <div className={`relative flex items-center gap-2 my-2`}>
                <input type="range" min={min} max={max} value={valeur} className="range range-xs range-accent "
                       onChange={handleChangeValue}/>
                <div className={`font-medium text-lg text-accent`}>{valeur}</div>
            </div>
        )
    }

    return (
        <div className={`flex flex-col text-normal my-2 rounded-xl`}>
            <div className={`font-thin`}><span>{handle_intitule(component_data)}</span> {component_data.requis ? (<span className={`text-error`}>*</span>) : ''} </div>
            {display_range(min,max,valeur)}
        </div>
    );
};

export { QuestionSlideRange };