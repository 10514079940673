// Définit le mapping entre les composants demandé depuis le back
// et les composants ci définis

import { QuestionSlideRange } from "../../../Bibliotheque/Composants/Metier/QuestionSlideRange";
import { QuestionAbstractTexte } from "../../../Bibliotheque/Composants/Metier/QuestionAbstractTexte";

const QuestionTexteFactory = ({key,questionNumber, dispatcher, sectionNumber, ...props}) => {
    return (<QuestionAbstractTexte key={key} questionNumber={questionNumber} dispatcher={dispatcher} sectionNumber={sectionNumber} {...props} />);
}

const QuestionRangeFactory = ({key,questionNumber, dispatcher, sectionNumber, ...props}) => {
    return (<QuestionSlideRange key={key} questionNumber={questionNumber} dispatcher={dispatcher} sectionNumber={sectionNumber} {...props} />)
}

const QuestionMapping = {
    QuestionTexte: QuestionTexteFactory,
    QuestionRange: QuestionRangeFactory
};


const getQuestionComponentFromMapping = (q,i, dispatcher, sectionNumber, externalQuestionMapping = {}) => {
    const { representation } = q;
    const { component } = representation;

    const MergedQuestionMapping = {...QuestionMapping, ...externalQuestionMapping};

    if( undefined === MergedQuestionMapping[component] ){
        return <div>{`Question ${component} non définie`}</div>
    }

    return MergedQuestionMapping[component]({key: i, questionNumber: i, dispatcher, sectionNumber, ...q})

}

export { getQuestionComponentFromMapping };