import {createContext, useContext, useEffect, useState} from "react";
import {DTOStack, DTOStackFactory} from "../Services/DTOStack";
import {getDTOStack, setDTOStack} from "../Services/storage.service";
import {inject_create_ou_update_evaluation_2} from "../Services/Injecteurs/injecteur.service";

const GlobalSynchronizationContext = createContext();

const GlobalSynchronizationProvider = ({children}) => {

    const [ syncFlag, setSyncFlag ] = useState(false);

    let DTOs = (new DTOStackFactory()).build(getDTOStack())
    // à sortir
    const pushDTO = (dto) => {
        DTOs.push(dto);
        let serializedDTOStack = DTOs.serialize()
        setDTOStack(serializedDTOStack);
        console.log(`Taille de la pile : ${DTOs.length}`);
    }

    const popDTO = () => {
        let dto = DTOs.pop();
        let serializedDTOStack = DTOs.serialize();
        setDTOStack(serializedDTOStack);
        return dto;
    }

    const peekDTO = () => {
        return DTOs.peek();
    }

    const findQuestionnaireDTOs = (questionnaire_id, instance_type_evaluable_id) => {
        let keys = DTOs.keysFor(questionnaire_id,instance_type_evaluable_id);
        let dtos = {}
        for ( const key of keys){
            dtos[key] = DTOs.at(key);
        }
        return dtos;
    }

    useEffect(() => {
        if ( syncFlag ){
            setSyncFlag(false)
            if( ! DTOs.empty ){
                let dto = peekDTO();
                inject_create_ou_update_evaluation_2(dto).then( reponse => {
                    popDTO();
                    setSyncFlag(true);
                }).catch(error => {
                    console.error('nok');
                })
            }
        }
    }, [syncFlag]);

    return (
        <GlobalSynchronizationContext.Provider value={{pushDTO, setSyncFlag, findQuestionnaireDTOs, syncFlag}}>
            {children}
        </GlobalSynchronizationContext.Provider>
    );

}

const useSyncToServer = () => {
    return useContext(GlobalSynchronizationContext);
}

export { useSyncToServer, GlobalSynchronizationProvider }