
const LocationDotIcon = ({className, ...props}) => {
    return (
        <svg viewBox="0 0 384 512" className={className}>
            <path
                d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/>
        </svg>
    );
}

const MobileIcon = ({className, ...props}) => {
    return (
        <svg className={className}
             viewBox="0 0 384 512">
            <path
                d="M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
        </svg>
    );
}

export { LocationDotIcon, MobileIcon }