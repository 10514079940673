import {useTranslation} from "react-i18next";

import {TypeTexte} from "./QuestionTypeTexte";
import {TypeZoneTexte} from "./QuestionTypeZoneTexte";

const QuestionAbstractTexte = ({ representation, ...component_data}) => {

    const {presentation} = component_data;

    const valeur = presentation?.valeur;

    const { t, i18n } = useTranslation();

    const handleIntitule = (component_data) => {
        const translatedIntitule = component_data?.translatedIntitule ?? {fr: '__intitule fr__', en: '__intitule en__'};
        const lang = i18n.language;
        return translatedIntitule[lang];
    }

    if ('Texte' === representation.type){
        return (
            <div className={`flex flex-col text-primary-content my-2 rounded-xl`}>
                <div className={`font-bold ${presentation.displayInvalide ? 'text-error' : ''}`}><span>{handleIntitule(component_data)}</span> {component_data.requis ? (<span className={`text-error`}>*</span>) : ''} </div>
                <TypeTexte valeur={valeur} error={presentation.displayInvalide} { ...component_data} />
            </div>
        );
    }

    if ('ZoneTexte' === representation.type){
        return (
            <div className={`flex flex-col text-primary-content my-2 border rounded-xl ${presentation.displayInvalide ? 'border-error' : 'border-transparent'}`}>
                <div className={`font-bold ${presentation.displayInvalide ? 'text-error' : ''} `}><span>{handleIntitule(component_data)}</span> {component_data.requis ? (<span className={`text-error`}>*</span>) : ''} </div>
                <TypeZoneTexte valeur={valeur} error={presentation.displayInvalide} {...component_data} />
            </div>
        );
    }
};

export {QuestionAbstractTexte};