import {Await, useLoaderData} from "react-router-dom";
import LoadingComponent from "../../../../Components/Elements/loading.component";
import {Suspense} from "react";
import {Description} from "../../../../Components/Presentation/Description";

import { OverridingMapping } from "../../Presentation/ComposantMapping";

const fallback_parser = (composant_name,composant_data,animation_data, key) => {
    return (<div key={key}>{`Composant ${composant_name} introuvable, construisez-en un !`}</div>);
}
const Questionnaire = (data) => {

    const loaded = useLoaderData();

    return (
        <Suspense fallback={<LoadingComponent/>}>
            <Await resolve={loaded.data}>
                <div className={`flex flex-col items-center justify-center w-full mx-auto space-y-10`}>
                    <Description projection={loaded.data.projection} fallback_parser={fallback_parser} overridenMapping={OverridingMapping}/>
                </div>
            </Await>
        </Suspense>
    );
}

export {Questionnaire};