import {IMAGE_URL} from "../../../Config";
import {SideCard} from "../../../Bibliotheque/Composants/Presentation/SideCard";

const SideCardReverseFactory = (parameters) => {
    const {projection_data,key,image,titre,texte,lien} = parameters;

    const img_path = (image) => {
        let returned_path = image;
        if (image.startsWith('_gallery_')){
            let image_number = image.match(new RegExp("_gallery_\\[([0-9]+)\\]"));
            image_number = image_number[1];
            let oImage = projection_data?.gallery[image_number];
            returned_path = `${IMAGE_URL}/${oImage?.path}`;
        }
        return returned_path;
    }

    return <SideCard key={key} flow={`reverse`} img_path={img_path(image)} texte={texte} titre={titre} lien={lien}/>
}

export { SideCardReverseFactory }