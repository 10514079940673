// !TODO transformer tout ça en autochargement et passer en adapteur de rendu

import {BannerFactory} from "../Factory/BannerFactory";

import {ParagrapheFactory} from "../Factory/ParagrapheFactory";
import {CarteFactory} from "../Factory/CarteFactory";

import {BoutonFactory} from "../Factory/BoutonFactory";
import {ImageFactory} from "../Factory/ImageFactory";
import {SwiperFactory} from "../Factory/SwiperFactory";

import {SideCardFactory} from "../Factory/SideCardFactory";

import {SideCardReverseFactory} from "../Factory/SideCardReverseFactory";

// pourquoi on s'en sert ?
import {BoutonAccesQuestionnaireMapping} from "./BoutonAccesQuestionnaireMapping";

import {StartEvaluationFactory} from "../Factory/StartEvaluationFactory";
import {StartEvaluationFinaleFactory} from "../Factory/StartEvaluationFinaleFactory";
import {ListeInstanceFactory} from "../Factory/ListeInstanceFactory";
import {TitreFactory} from "../Factory/TitreFactory";


const ComposantMapping = {
    Banner: BannerFactory,
    Titre: TitreFactory,
    Paragraphe: ParagrapheFactory,
    Carte: CarteFactory,
    Bouton: BoutonFactory,
    Image: ImageFactory,
    Swiper: SwiperFactory,
    SideCard: SideCardFactory,
    SideCardReverse: SideCardReverseFactory,
    BoutonAccesQuestionnaire: BoutonAccesQuestionnaireMapping,
    StartEvaluation: StartEvaluationFactory,
    StartEvaluationFinale: StartEvaluationFinaleFactory,
    ListeInstanceEvaluable: ListeInstanceFactory,
    Gallerie: () => {},
}

// renvoie le composant
const getComposantFromMapping = (composant_name, composant_data, fallback_parser, projection_data, key, overridenMapping) => {
    const CompMapping = { ...ComposantMapping, ...overridenMapping};
    if ( undefined === CompMapping[composant_name]){
        return fallback_parser(composant_name,composant_data,projection_data, key);
    }
    return CompMapping[composant_name]({...composant_data, projection_data, key});
}

export {getComposantFromMapping};