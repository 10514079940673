import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const GrandNavBouton = ({item}) => {

    const { t } = useTranslation()

    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(item.link);
    }


    return (
        <div className={`flex flex-wrap space-y-4 flex-col justify-center items-center p-4 border rounded-lg w-32 cursor-pointer fill-base-100 hover:fill-primary text-base-100 hover:text-primary`}
        onClick={handleNavigation}
        >
            <div className={`h-12 w-12`}>
                {item.logo()}
            </div>
            <div className={``}>{t(item.titre)}</div>
        </div>
    );
}

export {GrandNavBouton};