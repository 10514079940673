import Dexie from "dexie";
import {APP_THEME} from "../Config";

const database_name = `${APP_THEME}.app.db`;

const db = new Dexie(database_name);

// un agenda est une suite de questionnaire dont les évaluations sont
// ordonnées par dates de visite.
// id : int, questionnaires: object, version: string

// ! TODO normaliser les données pour que la base aie sa propre carac

// le flag dirty d'une réponse indique si elle a été synchronisée
if (db){
	db.version(82).stores({
		agenda: '++id, version, questionnaires',
		reponse: '++id, question_id, evaluation_id, valeur, repondu_le, dirty',
		type_evaluable: '++id, type_evaluable_id, type_evaluable',
		foodtruck: '++id, foodtruck_id, foodtruck',
		evaluations: '++id, questionnaire_id, evaluations',
		evaluation: '++id, evaluation_id, evaluation',
		questionnaires: '++id, questionnaires',
		questionnaire: '++id, [questionnaire_id+evaluable_id], questionnaire',
		evenements: '++id, evenements',
		evenement: '++id, evenement_id, evenement',
		animation: '++id, animation_id, animation',
		animations_publiques: '++id, evenement_id, animations_publiques',
		restaurants: '++id, evenement_id, restaurants',
		questionnaire_anonyme: '++id, restaurant_id, questionnaire_anonyme',
		questionnaire_identifie: '++id, [questionnaire_id+evaluable_id], questionnaire',
		// les evenement agendas sont liés à la date => la date devra être standardisée (nombre indexable)
		// Y_m_d : 2024_03_07
		evenement_agenda_today: '++id, [evenement_id+date] , evenement_agendas',
		evenement_agenda_future: '++id, [evenement_id+date], evenement_agendas',
		ownspace: '++id, evenement_id, ownspace',
		evenement_agenda: '++id, [evenement_id+date], evenement_agendas',
		evenement_agenda_id: '++id, evenement_agenda__id, evenement_agendas'
	});
}



const update_agenda = (db, data) => {
	return db.agenda.toCollection().first().then( (agenda_item) => {
		if ( ! agenda_item ){
			return db.agenda.add({evaluations: data.data.evaluations, version: 'la version'});
			// return db.agenda.add({questionnaires: data.data.questionnaires, version: 'la version'});
		}else{
			// return db.agenda.put({id: agenda_item.id, questionnaires: data.data.questionnaires, version: 'la version'})
			return db.agenda.put({id: agenda_item.id, evaluations: data.data.evaluations, version: 'la version'})
		}
	})
}

const update_questionnaires = (db, data) => {
	return db.questionnaires.toCollection().first().then( (questionnaires_item) => {
		if ( ! questionnaires_item ){
			return db.questionnaires.add({questionnaires: data.data});
		}else{
			return db.questionnaires.put({id: questionnaires_item.id, questionnaires: data.data})
		}
	})
}

const update_evenements = (db, data) => {
	return db.evenements.toCollection().first().then( ( evenements_item ) => {
		if ( ! evenements_item ){
			return db.evenements.add({evenements: data.data});
		}else{
			return db.evenements.put({id: evenements_item.id, evenements: data.data});
		}
	})
}

const update_evaluations = (db, questionnaire_id, data) => {
	return db.evaluations.where({questionnaire_id: questionnaire_id}).first()
		.then( (evaluations_item) => {
		if ( ! evaluations_item ){
			return db.evaluations.add({questionnaire_id: questionnaire_id, evaluations: data.data});
		}else{
			return db.evaluations.put({id: evaluations_item.id, questionnaire_id: questionnaire_id, evaluations: data.data})
		}
	})
}

const update_evaluation = (db, evaluation_id, data ) => {
	return db.evaluation.where({evaluation_id: evaluation_id}).first()
		.then( (evaluation_item) => {
			if ( ! evaluation_item ){
				return db.evaluation.add({evaluation_id: evaluation_id, evaluation: data.data});
			}else{
				return db.evaluation.put({id: evaluation_item.id, evaluation_id: evaluation_id, evaluation: data.data});
			}
		})
}
const update_evenement = (db, evenement_id, data ) => {
	return db.evenement.where({evenement_id: evenement_id}).first()
		.then( (evenement_item) =>{
			if ( ! evenement_item ){
				return db.evenement.add({evenement_id: evenement_id, evenement: data});
			}else{
				return db.evenement.put({id: evenement_item.id, evenement_id: evenement_id, evenement: data});
			}
		})
}

const update_animation = ( db, animation_id, data ) => {
	return db.animation.where({animation_id: animation_id}).first()
		.then( (animation_item) =>{
			if ( ! animation_item ){
				return db.animation.add({animation_id: animation_id, animation: data});
			}else{
				return db.animation.put({id: animation_item.id, animation_id: animation_id, animation: data});
			}
		})
}

const update_animations = ( db, evenement_id, data ) => {
	return db.animations_publiques.where({evenement_id: evenement_id}).first()
		.then( (animations_publiques_item) =>{
			if ( ! animations_publiques_item ){
				return db.animations_publiques.add({evenement_id: evenement_id, animations_publiques: data});
			}else{
				return db.animations_publiques.put({id: animations_publiques_item.id, evenement_id: evenement_id, animations_publiques: data});
			}
		})
}

const update_type_evaluable = ( db, type_evaluable_id, data ) => {
	return db.type_evaluable.where({type_evaluable_id: type_evaluable_id}).first()
		.then( (type_evaluable_item) =>{
			if ( ! type_evaluable_item ){
				return db.type_evaluable.add({type_evaluable_id: type_evaluable_id, type_evaluable: data});
			}else{
				return db.type_evaluable.put({id: type_evaluable_item.id, type_evaluable_id: type_evaluable_id, type_evaluable: data});
			}
		})
}

const update_foodtruck = ( db, foodtruck_id, data ) => {
	return db.foodtruck.where({foodtruck_id: foodtruck_id}).first()
		.then( (foodtruck_item) =>{
			if ( ! foodtruck_item ){
				return db.foodtruck.add({foodtruck_id: foodtruck_id, foodtruck: data});
			}else{
				return db.foodtruck.put({id: foodtruck_item.id, foodtruck_id: foodtruck_id, foodtruck: data});
			}
		})
}

const update_questionnaire = ( db, questionnaire_id, evaluable_id, data ) => {
	return db.questionnaire.where({questionnaire_id: questionnaire_id, evaluable_id: evaluable_id}).first()
		.then( (questionnaire_item) =>{
			if ( ! questionnaire_item ){
				return db.questionnaire.add({questionnaire_id: questionnaire_id, evaluable_id: evaluable_id, questionnaire: data});
			}else{
				return db.questionnaire.put({id: questionnaire_item.id, questionnaire_id: questionnaire_id, evaluable_id: evaluable_id, questionnaire: data});
			}
		})
}

const update_questionnaire_identifie = ( db, questionnaire_id, evaluable_id, data ) => {
	return db.questionnaire_identifie.where({questionnaire_id: questionnaire_id, evaluable_id: evaluable_id}).first()
		.then( (questionnaire_item) =>{
			if ( ! questionnaire_item ){
				return db.questionnaire_identifie.add({questionnaire_id: questionnaire_id, evaluable_id: evaluable_id, questionnaire: data});
			}else{
				return db.questionnaire_identifie.put({id: questionnaire_item.id, questionnaire_id: questionnaire_id, evaluable_id: evaluable_id, questionnaire: data});
			}
		})
}

const update_restaurants = ( db, evenement_id, data ) => {
	return db.restaurants.where( {evenement_id: evenement_id}).first()
		.then( (restaurants_item) => {
			if ( ! restaurants_item ){
				return db.restaurants.add({evenement_id: evenement_id, restaurants: data.data});
			}else {
				return db.restaurants.put({id: restaurants_item.id, evenement_id: evenement_id, restaurants: data.data});
			}
		})
}

const update_questionnaire_anonyme = ( db, restaurant_id, data ) => {
	return db.questionnaire_anonyme.where( {restaurant_id: restaurant_id}).first()
		.then( (questionnaire_anonyme_item) => {
			if ( ! questionnaire_anonyme_item ){
				return db.questionnaire_anonyme.add({restaurant_id: restaurant_id, questionnaire: data});
			}else {
				return db.questionnaire_anonyme.put({id: questionnaire_anonyme_item.id, restaurant_id: restaurant_id, questionnaire: data});
			}
		})
}



const update_reponse = (db, question_id, evaluation_id, valeur, dirty) => {
	return db.reponse.where({evaluation_id: evaluation_id, question_id: question_id}).first()
		.then( reponse_item => {
			// On stocke tout en utc iso 8601 [ATOM]
			const now_utc = (new Date()).toISOString();
			if ( ! reponse_item ){
				return db.reponse.add({question_id: question_id,
				valeur: valeur,
				evaluation_id: evaluation_id,
				repondu_le: now_utc,
				dirty: dirty});
			}else{
				return db.reponse.put({id: reponse_item.id,
					question_id: question_id,
					valeur: valeur,
					evaluation_id: evaluation_id,
					repondu_le: now_utc,
					dirty: dirty
				})
			}
		})
}

const update_evenement_agenda_today = (db, evenement_id, data ) => {

	let date = new Date();
	let date_string = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`;

	return db.evenement_agenda_today.where({evenement_id: evenement_id, date: date_string}).first()
		.then( evenement_agenda_today_item => {
			// si pas de réponse, on la construit
			if ( ! evenement_agenda_today_item ){
				return db.evenement_agenda_today.add({
					evenement_id : evenement_id,
					date: date_string,
					evenement_agendas: data
				});
			}else{
				// sinon on update la réponse de la base et on la renvoie
				return db.evenement_agenda_today.put({
					id: evenement_agenda_today_item.id,
					evenement_id: evenement_id,
					date: date_string,
					evenement_agendas: data
				});
			}
		})
}

const update_evenement_agenda_for_date = (db, evenement_id, date_string, data ) => {

	return db.evenement_agenda.where({evenement_id: evenement_id, date: date_string}).first()
		.then( evenement_agenda_item => {
			// si pas de réponse, on la construit
			if ( ! evenement_agenda_item ){
				return db.evenement_agenda.add({
					evenement_id : evenement_id,
					date: date_string,
					evenement_agendas: data
				});
			}else{
				// sinon on update la réponse de la base et on la renvoie
				return db.evenement_agenda.put({
					id: evenement_agenda_item.id,
					evenement_id: evenement_id,
					date: date_string,
					evenement_agendas: data
				});
			}
		})
}

const update_evenement_agenda_for_id = (db, evenement_agenda__id, data ) => {

	return db.evenement_agenda_id.where({evenement_agenda__id: evenement_agenda__id}).first()
		.then( evenement_agenda_item => {
			// si pas de réponse, on la construit
			if ( ! evenement_agenda_item ){
				return db.evenement_agenda_id.add({
					evenement_agenda__id : evenement_agenda__id,
					evenement_agendas: data
				});
			}else{
				// sinon on update la réponse de la base et on la renvoie
				return db.evenement_agenda_id.put({
					id: evenement_agenda_item.id,
					evenement_agenda__id: evenement_agenda__id,
					evenement_agendas: data
				});
			}
		})
}



const update_evenement_agenda_future = (db, evenement_id, data ) => {

	let date = new Date();
	let date_string = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`;

	return db.evenement_agenda_future.where({evenement_id: evenement_id, date: date_string}).first()
		.then( evenement_agenda_future_item => {
			// si pas de réponse, on la construit
			if ( ! evenement_agenda_future_item ){
				return db.evenement_agenda_future.add({
					evenement_id : evenement_id,
					date: date_string,
					evenement_agendas: data
				});
			}else{
				// sinon on update la réponse de la base et on la renvoie
				return db.evenement_agenda_future.put({
					id: evenement_agenda_future_item.id,
					evenement_id: evenement_id,
					date: date_string,
					evenement_agendas: data
				});
			}
		})
}

const update_ownspace = (db, evenement_id, data ) => {
	return db.ownspace.where({evenement_id: evenement_id}).first()
		.then( (ownspace_item) =>{
			if ( ! ownspace_item ){
				return db.ownspace.add({evenement_id: evenement_id, ownspace: data});
			}else{
				return db.ownspace.put({id: ownspace_item.id, evenement_id: evenement_id, ownspace: data});
			}
		})
}

const get_all_dirty_reponses = ( db ) => {
	return db.reponse.where({dirty: true});
}

export { db, update_agenda,
	update_questionnaires,
	update_evaluation,
	update_evaluations,
	update_reponse,
	update_evenements,
	update_evenement,
	update_animation,
	update_animations,
	update_restaurants,
	update_questionnaire_anonyme,
	update_evenement_agenda_today,
	update_evenement_agenda_future,
	update_evenement_agenda_for_date,
	update_evenement_agenda_for_id,
	update_type_evaluable,
	update_foodtruck,
	update_questionnaire,
	update_questionnaire_identifie,
	update_ownspace
};



