import { Router } from './Theme/festivalSxm2024';

class Container{

    getRouter()
    {
        return Router;
    }

}

export {Container};