import {IMAGE_URL} from "../../../Config";

const SplashImage = ({image_path}) => {
    const final_image_path = `${IMAGE_URL}/${image_path}`;

    return (
        <figure className={`h-full overflow-hidden w-full max-w-[1500px]`}>
            <img className={`max-h-64 lg:max-h-[33vh] w-full object-cover`} src={`${final_image_path}`}/>
        </figure>
    );
}

export {SplashImage}