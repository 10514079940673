import {useTranslation} from "react-i18next";

const Pager = ({nextHandle, prevHandle, nextSectionLabel,  ...props}) => {

    const { t, i18n } = useTranslation();

    return (
        <div className={`grow flex flex-col justify-end mb-12`}>
            <div className={`join `}>
                <button onClick={prevHandle}
                        className={`btn border border-black join-item bg-base-200 text-base-content hover:bg-accent hover:text-accent-content`}>{t('UI.Boutons.precedent')}</button>
                <button onClick={nextHandle}
                        className={`btn border border-black join-item bg-primary-content text-primary hover:bg-accent 
                        hover:text-accent-content grow md:grow-0 md:btn-wide flex justify-end items-center
                        fill-primary hover:fill-accent-content
                        `}>
                    <span>{nextSectionLabel()}</span>
                    <span className={`w-6 h-6 flex justify-center items-center`}>
                    <svg viewBox="0 0 448 512" >
                        <path
                            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                </span>
                </button>
            </div>
        </div>
    );
};

export {Pager};