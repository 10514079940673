import {useTranslation} from "react-i18next";

const StatusEvaluation = ({status, className, date_debut_evaluation, date_fin_questionnaire, ...props}) => {

    const {t, i18n} = useTranslation();

    const status_map = {
        ouverte: {
            label: t('UI.Badge.ouverte'),
            color: 'badge-info'
        },
        en_cours: {
            label: t('UI.Badge.encours'),
            color: 'badge-warning'
        },
        terminee: {
            label: t('UI.Badge.terminee'),
            color: 'badge-success'
        },
        nonouverte: {
            label: t('UI.Badge.nonouverte'),
            color: 'badge-neutral'
        },
        fermee: {
            label: t('UI.Badge.fermee'),
            color: 'badge-neutral'
        }
    };

    const now = new Date();
    if ( now < date_debut_evaluation){
        status = 'nonouverte';
    }
    if ( now > date_fin_questionnaire){
        status = 'fermee';
    }

    return (
        <div className={` ${className}`} {...props}>
            <div className={`tracking-wide font-normal text-xs md:text-small md:font-bold badge ${status_map[status]?.color}`}>
                {status_map[status]?.label}
            </div>
        </div>
    );
}

export {StatusEvaluation}