// Theme/festivalSxm2024/Page/index.js

// Construit et exporte la composition des pages

import {Page} from "../Layout/Page";
import {Enveloppe} from "../Layout/Enveloppe";

import {ConnexionScreen} from "./Connexion/ConnexionScreen";
import {EspaceMembre} from "./MySpace/EspaceMembre";
import {Home} from "./Home/Home";
import {PageFinDeFestival} from "./Home/PageFinDeFestival";
import {Animation} from "./Animation/Animation";
import {Stand} from "./Stands/Stand";
import {Foodtruck} from "./Foodtruck/Foodtruck";
import {Questionnaire} from "./Questionnaire/Questionnaire";
import {DeconnexionScreen} from "./Connexion/DeconnexionScreen";
import {Evaluable} from "./Evaluable/Evaluable";
import {Agenda} from "./Agenda/Agenda";
import {EvenementAgenda} from "./EvenementAgenda/EvenementAgenda";
import {Carte} from "./Carte/Carte";

const Pages = {
    PageConnexion: () => (<Enveloppe><Page><ConnexionScreen /></Page></Enveloppe>),
    PageEspaceMembre: () => (<Enveloppe><Page><EspaceMembre /></Page></Enveloppe>),
    PageHome: () => (<Enveloppe><Page><Home /></Page></Enveloppe>),
    PageAnimation: () => (<Enveloppe><Page><Animation /></Page></Enveloppe>),
    PageStand: () => (<Enveloppe><Page><Stand /></Page></Enveloppe>),
    PageFoodtruck: () => (<Enveloppe><Page><Foodtruck /></Page></Enveloppe>),
    PageQuestionnaire: () => (<Enveloppe><Page><Questionnaire /></Page></Enveloppe>),
    PageDeconnexion: () => (<Enveloppe><Page><DeconnexionScreen /></Page></Enveloppe>),
    PageEvaluable: () => (<Enveloppe><Page><Evaluable /></Page></Enveloppe>),
    PageAgenda: () => (<Enveloppe><Page><Agenda /></Page></Enveloppe>),
    PageCarte: () => (<Enveloppe><Page><Carte /></Page></Enveloppe>),
    PageEvenementAgenda: () => (<Enveloppe><Page><EvenementAgenda /></Page></Enveloppe>),
    PageFinDeFestival: () => (<Enveloppe><Page><PageFinDeFestival /></Page></Enveloppe>),
}

export { Pages };