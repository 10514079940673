import { DescriptionParser } from "./DescriptionParser";
import {useTranslation} from "react-i18next";

const Description = ({projection, fallback_parser, overridenMapping}) => {

    const { i18n } = useTranslation();

    // on est sur une projection lorsqu'on attaque startevaluation
    // on est en direct sur une animation
    let translatedDescription = projection?.translatedDescription;

    let current_translated_description = translatedDescription[i18n.language] ?? translatedDescription?.fr;

    const description = DescriptionParser(projection,current_translated_description, fallback_parser, overridenMapping);

    return (
        description.map( (i,k) => i)
    );
}

export { Description };