import L from 'leaflet';

const iconMarker = new L.Icon({
		iconUrl: require('../../Images/place_gold_24dp.png'),
		// iconRetinaUrl: require('../../Images/place_black_24dp.png'),
		// iconAnchor: null,
		// popupAnchor: null,
		// shadowUrl: null,
		// shadowSize: null,
		// shadowAnchor: null,
		iconSize: new L.Point(25,70),
		// className: 'leaflet-div-icon'
});

export { iconMarker };
