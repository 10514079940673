import {getQuestionComponentFromMapping} from "./QuestionMapping";
import {useTranslation} from "react-i18next";

const Section = ({ questionnaireState, dispatcher, ...props}) => {

    const sectionNumber = questionnaireState.current_section;
    const questionnaire_data = questionnaireState.questionnaire_data;

    const {questionMapping} = props;

    // const questionnaire_data = useAsyncValue();

    const { t, i18n } = useTranslation();
    const section_data = questionnaire_data?.sections[sectionNumber] ?? {};
    const questions = section_data?.questions ?? [];

    // voir si on peut curryfier, ce serait trop sympa
    let questionsComponentsAsArray = [];
    const question_composants = questions.map( (q,i) => {
        const question_composant = getQuestionComponentFromMapping(q,i,dispatcher, sectionNumber, questionMapping);
        questionsComponentsAsArray.push(question_composant);
    });

    const handleLibelle = (sectionData) => {
        const lang = i18n.language;
        let libelle = sectionData?.translatedLibelle ?? {fr: '__libelle fr__', en: '__libelle en__'};
        return libelle[lang];
    }

    return (
        <section className={`flex flex-col space-y-4 my-8`}>
            <h2 className={`font-extrabold text-2xl text-primary-content`}>{handleLibelle(section_data)}</h2>
            <div className={`container-questions flex flex-col`}>
                { questionsComponentsAsArray.map( (qc) => qc ) }
            </div>
        </section>
    )

};

export { Section };