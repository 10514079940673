import {PuceEvenementAgendaAVenir} from "./PuceEvenementAgendaAVenir";


const ListeEvenementAVenir = ({evenement_agendas}) => {
    return (
        <div className={`flex flex-row items-center justify-start overflow-x-auto overflow-y-hidden gap-4 w-full col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4`}>
            {
                evenement_agendas.map( (evenement_agenda,i) => (<PuceEvenementAgendaAVenir key={i} evenement_agenda={evenement_agenda}/>))
            }
        </div>
    )
}

export {ListeEvenementAVenir}