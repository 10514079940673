import { TimelineItem } from "./TimelineItem";
import { TimeService } from "../../../Services/time.service";
import {useTranslation} from "react-i18next";

const sort_bucket_animations = (animations, locale, t) => {
    // on tri les animations par date d'entrees de programme croissante
    // et on cloisonne par jour puis pas date de début
    let animations_bucketed =  [];

    let temp_tab_date_ordonnee = [];
    let temp_tab_index_animation = [];

    animations.forEach( (animation, index) => {
        if (!animation.entree_programmes) {
            console.log(`Erreur : pas d'entrées programme pour l'animation ?, animation id ${animation.id}`);
        }
        if (!animation.id) {
            console.log("Erreur : pas d'id animation ?");
        }

        let n_entrees_programme = animation.entree_programmes.length;
        if (0 === n_entrees_programme){
            console.log("Erreur : pas d'entrées pour le programme de l'animation");
            console.log(animation);
        }


        let date = Date.parse(animation.entree_programmes[0].date_debut);
        date = (new TimeService().convertDateTimeStringToJSDateTimeObjet(animation.entree_programmes[0].date_debut));

        let id = animation.id;

        let indice = 0;
        for (indice = 0; indice < temp_tab_date_ordonnee.length; indice++) {
            if (date <= temp_tab_date_ordonnee[indice]) {
                break;
            }
        }
        if (indice < temp_tab_date_ordonnee.length) {
            temp_tab_date_ordonnee.splice(indice, 0, date)
            temp_tab_index_animation.splice(indice, 0, index);
        } else {
            temp_tab_date_ordonnee.push(date);
            temp_tab_index_animation.push(index);
        }
    });

    // Les indices dans temp_tab_index_animation contiennent les indices dans le tableau
    // animations des animations ordonnées par date de début croissante

    temp_tab_index_animation.forEach( (indice_animation) => {
        let animation = animations[indice_animation];

        let n_entrees_programme = animation.entree_programmes.length;
        let prefixe = '';
        if (n_entrees_programme > 1) {
            prefixe = "début à ";
        }
        let date = Date.parse(animation.entree_programmes[0].date_debut);
        let timeservice = new TimeService();
        date = timeservice.convertDateTimeStringToJSDateTimeObjet(animation.entree_programmes[0].date_debut);
        let premiere_partie_date = timeservice.convertDateToLocaleDateString(date, locale);
        let seconde_partie_date = timeservice.convertDateToLocaleDateTimeString(date, locale);

        let i = 0;
        let j = 0;
        // toutes les animations qui entrent dans l'algo sont triées par date de début
        for (i=0; i<animations_bucketed.length; i++){
            const [premiere_partie_date_a_comparer,tableau_programme] = Object.entries(animations_bucketed[i])[0];
            if ( premiere_partie_date === premiere_partie_date_a_comparer){
                for (j=0; j<animations_bucketed[i][`${premiere_partie_date}`].length; j++){
                    const [seconde_partie_date_a_comparer, tableau_animation] = Object.entries(animations_bucketed[i][`${premiere_partie_date}`][j])[0];
                    if ( seconde_partie_date === seconde_partie_date_a_comparer ){
                        animations_bucketed[i][`${premiere_partie_date}`][j][`${seconde_partie_date}`].push(animation);
                        break;
                    }
                }
                if (j>=animations_bucketed[i][`${premiere_partie_date}`].length){
                    let new_object_programme = {};
                    new_object_programme[`${seconde_partie_date}`] = [animation];
                    animations_bucketed[i][`${premiere_partie_date}`].push(new_object_programme);
                }
                break;
            }
        }
        if (i>=animations_bucketed.length){
            let new_object_journee = {};
            new_object_journee[`${premiere_partie_date}`] = [];
            let new_object_programme = {};
            new_object_programme[`${seconde_partie_date}`] = [];
            new_object_programme[`${seconde_partie_date}`].push(animation);
            new_object_journee[`${premiere_partie_date}`].push(new_object_programme);
            animations_bucketed.push(new_object_journee);
        }
    });

    return animations_bucketed;
}
const convert_animations_to_timeline_items = (animations, locale, t) => {

    const animations_bucketed = sort_bucket_animations(animations, locale, t);

    // on commence à start
    // tous les pairs on est à start, les impairs à end
    // on préfixe on ajoute "Debut de l'évènement"
    // on postfixe "Fin de l'évènement"

    let timeline_items = [];

    animations_bucketed.forEach( (animation_datetimee, index_date) => {
        let [date_animation, suite_date_animation] = Object.entries(animation_datetimee)[0];
        // On arrive, on push la date

        if (0===index_date){
            timeline_items.push({
                date: `${date_animation}`,
                item: (<div className={`text-lg font-black text-accent`}>{t('Components.TimeLine.debutdufestival')}</div> ),
                direction: 'start',
                variation: 'plein'
            });
        }
        else{
            timeline_items.push({
                date: `${date_animation}`,
                direction: (0===(index_date%2)) ? 'start' : 'end',
                variation: 'plein'
            })
        }

        suite_date_animation.forEach( (animation_timestampee, index_time) => {
            // des animations peuvent commencer en parallèle au même moment
            let [time_animation, animations_paralleles] = Object.entries(animation_timestampee)[0];
            animations_paralleles.forEach( (animation) => {
                timeline_items.push({
                    date: `${time_animation}`,
                    item: (<div className={`text-md ${0===index_date%2? 'text-end':'text-start'} `}>
                        <a className={`underline decoration-accent`} href={`/animation/${animation.id}`}>
                        {`${animation.translatedNom['fr']}`}
                        </a>
                    </div>),
                    direction: (0===(index_date%2)) ? 'start' : 'end',
                    variation: 'creux'
                });
            })
        })
    })

    // dernier jour de festival :
    const n_days = animations_bucketed.length;
    // const [date_fermeture, dont_care] = Object.entries(animations_bucketed[n_days-1])[0];
    timeline_items.push({
        // date: `${date_fermeture}`,
        item: (<div className={`text-lg font-black text-accent`}>{t('Components.TimeLine.findufestival')}</div> ),
        direction: (0===(n_days%2)) ? 'start' : 'end',
        variation: 'plein'
    });
    return timeline_items;

};

const Timeline = ( props ) => {

    const {data, animations} = props;

    const { i18n, t } = useTranslation();

    let time_items = convert_animations_to_timeline_items(animations, i18n.language, t);

    return (
            <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
                {
                    time_items.map((timeline_item, item_index) => {

                        return (
                            <TimelineItem
                                key={item_index}
                                item_date={timeline_item.date}
                                direction={timeline_item.direction}
                                variation={timeline_item.variation}
                                item_index={item_index}
                                n_items={time_items.length}
                            >
                                {timeline_item.item}
                            </TimelineItem>
                        );
                    })
                }
            </ul>
        // </>
    );
};

export {Timeline};