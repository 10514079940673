import {MapContainer, Marker, TileLayer} from "react-leaflet";

const Carte = (props) => {
    const {position, className} = props;

    const lat = (position[0] + "").trim();
    const lon = (position[1] + "").trim();
    const destination_uri_part = encodeURIComponent(`${lat},${lon}`);
    let direction_link = `https://google.com/maps/dir/?api=1&destination=${destination_uri_part}&zoom=1`;
    let android_direction_link=`geo:0,0?q=${lat},${lon}`;
    let apple_direction_link = `https://maps.apple.com/?ll=${lat},${lon}&z=21&dirflg=d&t=h`;

    return (
        <>
            <div className={`flex my-4 gap-4`}>
                <a href={`${android_direction_link}`} target='_blank' className={`flex flex-col items-center`}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className={`w-12 h-12 p-1 fill-accent`}
                         viewBox="0 0 512 512">
                        <path
                            d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm306.7 69.1L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
                    </svg>
                    <span className={`text-xs`}>Android</span>
                </a>
                <a href={`${apple_direction_link}`} target='_blank' className={`flex flex-col items-center`}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className={`w-12 h-12 p-1 fill-accent`}
                         viewBox="0 0 512 512">
                        <path
                            d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm306.7 69.1L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
                    </svg>
                    <span className={`text-xs`}>Iphone</span>
                </a>
            </div>
            <div className={`${className} min-h-[600px] w-full`}>
                {/*<div className="flex flex-row justify-between my-2">*/}
                {/*    <BackButton />*/}
                {/*    <div className="itineraires flex flex-row gap-2">*/}
                {/*        <div className="text-white text-md">{t('Carte.itineraire')}</div>*/}
                {/*        <a href={`https://maps.apple.com/?ll=${x},${y}&z=21&dirflg=d&t=h`} target="__blank" className="">*/}
                {/*            <svg className="h-[32px] w-[50px] fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/></svg>*/}
                {/*        </a>*/}
                {/*        <a href={`geo:${x},${y}`} target="__blank" className="">*/}
                {/*            <svg className="h-[32px] w-[50px] fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"/></svg>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={`map-container`}>
                    <MapContainer center={position} zoom={14} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {/*<TileLayer*/}
                        {/*    attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'*/}
                        {/*    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"*/}
                        {/*/>*/}
                        <Marker position={position}></Marker>
                    </MapContainer>
                </div>
            </div>
        </>

    );
}

export {Carte};