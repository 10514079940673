const Video = (props) => {
    const {className, videoSrc} = props;
    return (
        <div className={`flex flex-col justify-center items-center relative w-[100vw] overflow-hidden`}>
            <video className={`relative top-0 left-0 w-full `} autoPlay={true} muted={true} playsInline={true} loop={true}>
                <source src={videoSrc} type="video/mp4" />
            </video>
            <div className={`absolute top-0 left-0 w-full h-full bg-primary opacity-0`}>
            </div>
        </div>
    );
};

export {
    Video
};