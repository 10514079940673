import axios from "axios";
import {API_URL, APP_USER} from "../Config";
import {
    getAnonymousRefreshToken, getAnonymousUserEmail, getAnonymousUserPassword, getAnonymousUserToken, getEvenementId,
    getIdentifiedRefreshToken, getIdentifiedUserToken,
    setAnonymousRefreshToken,
    setAnonymousUserToken, setEvenementId, setIdentifiedRefreshToken, setIdentifiedUserToken
} from "./storage.service";
import fetchAdapter from "./axios.fetch.adapter";


const USER_TYPE_ANONYMOUS = 'anonymous';
const USER_TYPE_IDENTIFIED = 'identified';
const net_refresh = async ( user_type ) => {
    let refresh_token = undefined;
    if ( USER_TYPE_ANONYMOUS === user_type ){
        refresh_token = getAnonymousRefreshToken();
    }
    if ( USER_TYPE_IDENTIFIED === user_type ){
        refresh_token = getIdentifiedRefreshToken();
    }
    try{
        if ( ! refresh_token ){
            throw ('pas de jeton de rafraichissement');
        }
        const response = await axios({
            method: "post",
            url: `${API_URL}/token/refresh`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                refresh_token: `${refresh_token}`
            },
        });

        const user_token = response.data.user_token;
        const refresh_token = response.data.refresh_token;

        if ( USER_TYPE_ANONYMOUS === user_type ){
            setAnonymousRefreshToken(refresh_token);
            setAnonymousUserToken(user_token);
        }
        if ( USER_TYPE_IDENTIFIED === user_type ){
            setIdentifiedRefreshToken(refresh_token);
            setIdentifiedUserToken(user_token);
        }

        return 'ok';
    }catch(e){
        throw(e);
    }
}

const net_login = async ( email, password, user_type ) => {
    try{
        const response = await axios({
            method: "post",
            url: `${API_URL}/login`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { email, password },
        });

        const user_token = response.data.token;
        const refresh_token = response.data.refresh_token;
        // si c'est utilisateur anonyme, l'app fait que l'utilisateur est
        // associé à un et un seul évènement
        // si c'est un utilisateur identifié, l'utilisateur pouvant
        // être associé à plusieurs évènements, l'évènement id
        // n'est pas dans la réponse
        const evenement_id = response.data.evenement_id;

        evenement_id && setEvenementId(evenement_id);

        if ( USER_TYPE_ANONYMOUS === user_type ){
            setAnonymousRefreshToken(refresh_token);
            setAnonymousUserToken(user_token);
        }
        if ( USER_TYPE_IDENTIFIED === user_type ){
            setIdentifiedRefreshToken(refresh_token);
            setIdentifiedUserToken(user_token);
        }

        return 'ok';
    }catch(e){
        throw(e);
    }
}

const net_login_anonymous = async () => {
    let anonymous_password = getAnonymousUserPassword();
    let anonymous_email = getAnonymousUserEmail();

    let login_anonymous_user_response;
    try{
        login_anonymous_user_response = await axios({
            url: `${API_URL}/login`,
            method: 'POST',
            header: {
                "Content-Type": "application/json",
            },
            data: {
                email: anonymous_email,
                password: anonymous_password,
            },
            adapter: fetchAdapter
        });
    }catch(e){
        login_anonymous_user_response = e.response;
    }

    return login_anonymous_user_response;
}

const net_create_anonymous = async () => {
    let connect_app_response;
    try{
        connect_app_response = await axios({
            url: `${API_URL}/connect`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                app_id: APP_USER
            },
            adapter: fetchAdapter
        });
    }catch(e){
        connect_app_response = e.response;
    }

    return connect_app_response;
}

const net_fetch_evenement = async ( evenement_id, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter,
        });
    }catch(e){
        response = e.response;
    }
    return response;
}

const net_fetch_animation = async ( animation_id, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/animation/${animation_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter,
        });
    }catch(e){
        response = e.response;
    }
    return response;
}

const net_fetch_animations = async ( evenement_id, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/animation`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter
        });
    }catch(e){
        response = e.response;
    }
    return response;
}

const net_fetch_restaurants = async ( evenement_id, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/restaurant`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter,
        });
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_questionnaire_anonyme = async ( evenement_id, restaurant_id, user_token ) => {

    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/restaurant/${restaurant_id}/evaluation`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_liste_questionnaires = async ( evenement_id, user_token ) =>
{
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/questionnaire`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },

            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_type_evaluable = async ( type_evaluable_id, evenement_id ,user_token ) =>
{
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/typeevaluable/${type_evaluable_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },

            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_foodtruck = async ( foodtruck_id, evenement_id ,user_token ) =>
{
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/foodtruck/${foodtruck_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },

            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_questionnaire = async ( questionnaire_id, competiteur_id, evenement_id ,user_token ) =>
{
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/questionnaire/${questionnaire_id}?competiteur_id=${competiteur_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },

            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_liste_evaluations = async ( questionnaire_id, user_token ) =>
{
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evaluations/filter/questionnnaire/${questionnaire_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_refresh_token = async ( refresh_token ) => {
    let refresh_response;
    try{
        refresh_response = await axios({
            url: `${API_URL}/token/refresh`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                refresh_token: refresh_token,
            },
            adapter: fetchAdapter,
        });

    }catch(e){
        refresh_response = e.response;
    }
    return refresh_response
}

const net_create_evaluation_anonyme = async ( payload, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evaluation`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            data: payload,
            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_update_evaluation = async( evaluation_id, payload, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evaluation/${evaluation_id}`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            data: payload,
            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_evaluation = async ( evaluation_id, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evaluation/${evaluation_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

// l'app récupère son token id
const net_fetch_evenement_id = async () => {
    let app_user = APP_USER;
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/current/${app_user}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
            adapter: fetchAdapter,
        })
    }catch(e){
        response = e.response;
    }

    return response;
}

// récupération des événément-agendas pour la journée
// on assume un utilsateur chefs, les utilisateurs chefs n'ont pas d'agenda.
const net_fetch_evenement_agenda_for_today = async() => {
    let evenement_id = getEvenementId();
    let user_token = getIdentifiedUserToken();
    let response;

    if ( !evenement_id ){
        try{
            await net_fetch_evenement_id();
        }catch(e){
            // si une erreur se produit alors qu'on fetch l'évènement id,
            // on est bloqué et on sort
            response = e.response;
            return response;
        }
    }

    evenement_id = getEvenementId();

    try{
        response = await axios({
            url: `${API_URL}/evenement-agenda/${evenement_id}/today`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter
        });
    }catch(e){
        response = e.response;
    }

    return response;
}

// récupération des événément-agendas à venir à partir de demain
// on assume un utilsateur chefs, les utilisateurs chefs n'ont pas d'agenda.
const net_fetch_evenement_agenda_to_come = async() => {
    let evenement_id = getEvenementId();
    let user_token = getIdentifiedUserToken();
    let response;

    if ( !evenement_id ){
        try{
            await net_fetch_evenement_id();
        }catch(e){
            // si une erreur se produit alors qu'on fetch l'évènement id,
            // on est bloqué et on sort
            response = e.response;
            return response;
        }
    }

    evenement_id = getEvenementId();

    try{
        response = await axios({
            url: `${API_URL}/evenement-agenda/${evenement_id}/future`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter
        });
    }catch(e){
        response = e.response;
    }

    return response;
}

// date_string format : 20241025
const net_fetch_evenement_agenda_for_date = async(date_string) => {
    let evenement_id = getEvenementId();
    let user_token = getIdentifiedUserToken();
    let response;

    if ( !evenement_id ){
        try{
            await net_fetch_evenement_id();
        }catch(e){
            // si une erreur se produit alors qu'on fetch l'évènement id,
            // on est bloqué et on sort
            response = e.response;
            return response;
        }
    }

    evenement_id = getEvenementId();

    try{
        response = await axios({
            url: `${API_URL}/evenement-agenda/${evenement_id}/${date_string}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter
        });
    }catch(e){
        response = e.response;
    }

    return response;
}

const net_fetch_evenement_agenda_for_id = async(evenement_agenda_id) => {
    let evenement_id = getEvenementId();
    let user_token = getIdentifiedUserToken();
    let response;

    if ( !evenement_id ){
        try{
            await net_fetch_evenement_id();
        }catch(e){
            // si une erreur se produit alors qu'on fetch l'évènement id,
            // on est bloqué et on sort
            response = e.response;
            return response;
        }
    }

    evenement_id = getEvenementId();

    try{
        response = await axios({
            url: `${API_URL}/evenement-agenda/${evenement_agenda_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },
            adapter: fetchAdapter
        });
    }catch(e){
        response = e.response;
    }

    return response;
}


async function net_update_or_create_evaluation(questionnaireDTO, userToken){

    // const data = JSON.stringify(questionnaireDTO);

    let update_or_create_evaluation_response;
    try{
        update_or_create_evaluation_response = await axios({
            url: `${API_URL}/stub/evaluation`,
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${userToken}`
            },
            data: questionnaireDTO,

            adapter: fetchAdapter
        });
    }catch(e){
        update_or_create_evaluation_response = e.response;
    }

    return update_or_create_evaluation_response;
}

const net_fetch_myhome = async ( evenement_id, user_token ) => {
    let response;
    try{
        response = await axios({
            url: `${API_URL}/evenement/${evenement_id}/ownspace`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` Bearer ${user_token}`
            },

            adapter: fetchAdapter,
        });
    }catch(e){
        response = e.response;
    }
    return response;
}


export {
    USER_TYPE_IDENTIFIED,
    USER_TYPE_ANONYMOUS,
    net_login,
    net_refresh,

    net_login_anonymous,
    net_create_anonymous,
    net_fetch_evenement,
    net_refresh_token,
    net_fetch_animation,
    net_fetch_restaurants,
    net_fetch_questionnaire_anonyme,
    net_create_evaluation_anonyme,
    net_fetch_evenement_id,
    net_update_evaluation,
    net_fetch_evaluation,
    net_fetch_liste_questionnaires,
    net_fetch_liste_evaluations,
    net_fetch_evenement_agenda_for_today,
    net_fetch_evenement_agenda_to_come,
    net_fetch_evenement_agenda_for_date,
    net_fetch_evenement_agenda_for_id,
    net_fetch_animations,
    net_fetch_type_evaluable,
    net_fetch_foodtruck,
    net_fetch_questionnaire,
    net_fetch_myhome,

    net_update_or_create_evaluation
}