// Theme/scrprpod/Components/Sections/ListeEvenementAgendaAujourdhui.js
//
// Affiche la liste des evenements agenda pour aujourdhui

import {useNavigate} from "react-router-dom";
import {IMAGE_URL} from "../../../Config";
import {useTranslation} from "react-i18next";
import {PuceEvenementAgendaAujourdhui} from "./PuceEvenementAgendaAujourdhui";
import {PuceAccordeonEvenementAgendaAujourdhui} from "./PuceAccordeonEvenementAgendaAujourdhui";
import {PuceEvenementAgenda} from "./PuceEvenementAgenda";

// Affiche la liste des évènements agenda possédant soit une liste d'évaluations associées
// soit une liste d'animations associée

const ListeEvenementAgendaAujourdhui = ({evenement_agendas}) => {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const displayListeEvenements = ( evenement_agendas ) => {
        if ( !evenement_agendas || evenement_agendas.length === 0 || evenement_agendas.length === undefined){
            return (<div>{t('Components.ListeQuotidienneEvenementAgendas.empty')}</div>);
        }
        return (
            evenement_agendas.map( (evenement_agenda, index) => {
                    // return ( evenement_agenda?.evaluations?.length >= 2 ?
                    //         (<PuceAccordeonEvenementAgendaAujourdhui key={index} evenement_agenda={evenement_agenda} />) :
                    //         (<PuceEvenementAgendaAujourdhui key={index} evenement_agenda={evenement_agenda} />)
                    // );
                return (<PuceEvenementAgenda key={index} evenement_agenda={evenement_agenda} />)
                })
        )
    }

    return (
        <>
            {displayListeEvenements(evenement_agendas)}
        </>
    );
}

export default ListeEvenementAgendaAujourdhui