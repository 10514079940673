// /Theme/scrprod/Layout/Footer.js
// definition du footer du theme

import {useOnlineState} from "../../../Providers/onlinestatequeryable.provider";
import {useTranslation} from "react-i18next";

const IconOnline = () => {

    const { t } = useTranslation();

    return (
        <span className={`flex flex-row items-center space-x-2`}>
        <svg viewBox="0 0 512.001 512" className={`w-4 fill-accent m-2`}>
<g>
	<path d="M48,181.063c0,55.563,21.656,107.813,61,147.125l-33.938,33.938C26.656,313.75,0,249.438,0,181.063s26.625-132.688,75-181
		L108.938,34C69.625,73.25,48,125.5,48,181.063z M182.438,107.5L148.5,73.562c-28.688,28.75-44.5,66.938-44.5,107.5
		c0,40.625,15.813,78.813,44.563,107.5l33.938-33.938c-19.688-19.625-30.5-45.75-30.5-73.563
		C152,153.313,162.813,127.188,182.438,107.5z M436.938,0L403,33.938c39.344,39.313,61,91.563,61,147.125
		s-21.625,107.813-60.906,147.125l33.938,33.938c48.344-48.375,74.969-112.688,74.969-181.063C512,112.625,485.344,48.313,436.938,0
		z M256,133.063c-26.5,0-48,21.5-48,48c0,17.75,9.719,33.063,24,41.344V512h48V222.438c14.281-8.313,24-23.625,24-41.375
		C304,154.563,282.531,133.063,256,133.063z M363.438,73.563L329.5,107.5c19.656,19.688,30.5,45.813,30.5,73.563
		c0,27.813-10.812,53.938-30.438,73.563l33.938,33.938c28.688-28.688,44.5-66.875,44.5-107.5S392.188,102.313,363.438,73.563z"/>
</g>
</svg>
            <span className={`text-success`}>{t('Footer.enligne')}</span>
            </span>
    );
}

const IconOffline = () => {

    const {t} = useTranslation();

    return (
        <span className={`flex flex-row items-center space-x-2`}>
        <svg className={`w-4 fill-accent m-2`} viewBox="0 0 24 24"><g clip-path="url(#clip0_1651_3963)"><path
            fill-rule="evenodd" clip-rule="evenodd"
            d="M2.00015 0.585815L3.93487 2.52053C4.02836 2.41985 4.12406 2.32052 4.22198 2.22261L4.92908 1.5155L6.3433 2.92972L5.63619 3.63682C5.53811 3.7349 5.4428 3.83464 5.35026 3.93593L6.76638 5.35205C6.85764 5.24946 6.95239 5.14897 7.05063 5.05073L7.75773 4.34363L9.17195 5.75784L8.46484 6.46495C8.36615 6.56364 8.27254 6.6653 8.18397 6.76964L9.60626 8.19193C10.1539 7.46787 11.0225 7.00003 12.0004 7.00003C13.6572 7.00003 15.0004 8.34317 15.0004 10C15.0004 10.9779 14.5325 11.8465 13.8085 12.3941L15.2429 13.8286L15.5355 13.536C17.4881 11.5834 17.4881 8.41757 15.5355 6.46495L14.8283 5.75784L16.2426 4.34363L16.9497 5.05073C19.6833 7.78441 19.6833 12.2166 16.9497 14.9502L16.6571 15.2428L18.0718 16.6575L18.3646 16.3647C21.8793 12.85 21.8793 7.15154 18.3646 3.63682L17.6574 2.92972L19.0717 1.5155L19.7788 2.22261C24.0745 6.51838 24.0745 13.4832 19.7788 17.779L19.486 18.0717L23.4144 22L22.0002 23.4142L0.585938 2.00003L2.00015 0.585815ZM12.3511 10.9368C12.7304 10.7947 13.0004 10.4289 13.0004 10C13.0004 9.44774 12.5527 9.00003 12.0004 9.00003C11.5715 9.00003 11.2057 9.27001 11.0636 9.64928L12.3511 10.9368Z"
            /><path
            d="M3.42815 7.07466L3.21258 8.05115C2.57158 10.9547 3.38081 14.1094 5.63619 16.3647L6.3433 17.0719L4.92908 18.4861L4.22198 17.779C1.46373 15.0207 0.477626 11.1622 1.2596 7.62L1.47517 6.64352L3.42815 7.07466Z"
            /><path
            d="M6.94184 10.6448L7.2594 11.5931C7.49685 12.3021 7.89783 12.969 8.46484 13.536L9.17195 14.2431L7.75773 15.6573L7.05063 14.9502C6.25987 14.1595 5.69646 13.2242 5.36292 12.2282L5.04536 11.28L6.94184 10.6448Z"
            /><path d="M13.0004 16.5999V22H11.0004V16.5999H13.0004Z" /></g><defs><clipPath
            id="clip0_1651_3963"><rect width="24" height="24" /></clipPath></defs></svg>
            <span className={`text-error`}>{t('Footer.horsligne')}</span>
        </span>
    );
}


const Footer = () => {

    const {isOnline} = useOnlineState();

    return (<footer className={`max-h-fit px-2 py-1 flex flex-row justify-between items-center w-full`}>
        <span className={`text-accent text-xs`}>©{(new Date()).getFullYear()} Chef Pro Link</span>
        <span className={`text-accent text-xs`}>
            { isOnline ? <IconOnline /> : <IconOffline /> }
        </span>
    </footer>);
};

export {Footer};