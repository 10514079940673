import {LocationDotIcon, MobileIcon} from "./Icones";

import {Carte} from '../Presentation/Carte';

const CoordonneesEvaluable = ({lieu, telephone, nom, className, ...props}) => {

    const encoded_adresse = lieu?.adresse ? `?adresse=${encodeURIComponent(lieu.adresse)}` : '';
    return (telephone || lieu) ? (
        <div className={`w-full max-w-3xl flex flex-col justify-start items-start gap-6 m-auto bg-base-200 p-4 rounded-xl`}>
            { lieu ?
                (
                    // <details className="collapse">
                    //     <summary className="collapse-title">
                    //         <div className={`w-full flex flex-row justify-start gap-2 items-center`}>
                    //             <LocationDotIcon className={`fill-base-content w-6 h-6`}/>
                    //             <span className={`font-light text-md text-base-content`}>{lieu?.adresse}</span>
                    //         </div>
                    //     </summary>
                    //     <div className="collapse-content">
                    //     <Carte position={[lieu?.lat, lieu?.lon]} />
                    //     </div>
                    // </details>
                    <a href={`/carte/${lieu?.lat}/${lieu?.lon}/${encodeURIComponent(nom)}${encoded_adresse}`} className={`w-full flex flex-row justify-start gap-2 items-center`}>
                        <LocationDotIcon className={`fill-base-content w-6 h-6`}/>
                        <span className={`font-light text-md text-base-content`}>{lieu?.adresse}</span>
                    </a>

                ) : (<></>)
            }
            { telephone ?
                (<a href={`tel:${telephone}`} target="_blank" rel="noopener noreferrer" className={`w-full flex flex-row justify-start gap-2 items-center`}>
                    <MobileIcon className={`fill-base-content w-6 h-6`}/>
                    <span className={`font-light text-md text-base-content`}>{telephone}</span>
                </a>) : (<></>)
            }
        </div>
    ) : (<></>);

}

export {CoordonneesEvaluable}