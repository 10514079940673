import {useNavigate} from "react-router-dom";

const SideCard = ({flow,img_path,texte,titre,lien}) => {
    const navigate = useNavigate();

    const handeButtonClick = (event) => {
        navigate(lien.href);
    }

    const displayBody = () => {
        return (
            <div className="card-body">
                {
                    (() => titre ? (<h2 className="card-title">{titre}</h2>) : (<></>))()
                }
                {
                    (() => texte ? (<p>{texte}</p>) : (<></>))()
                }
                {
                    (() => lien ? (<div className="card-actions justify-end mt-auto">
                        <button onClick={handeButtonClick} className="btn btn-primary ">{lien?.text}</button>
                    </div>) : (<></>))()
                }
            </div>
        );
    }

    const displayImage = () => {
        return (
            <figure className={`w-full lg:max-w-[50%]`}><img src={`${img_path}`} alt="Album"/>
            </figure>
        );
    }

    return (
        <div className={`card card-side bg-base-100 shadow-xl w-full md:max-w-[75%] lg:max-w-[50%] `}>
            { 'reverse' === flow ?
                (<>{displayBody()}{displayImage()}</>) :
                (<>{displayImage()}{displayBody()}</>)
            }
        </div>
    );
}

export {SideCard}