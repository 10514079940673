// Theme/scrprod/Layout/Enveloppe.js
// décore la page {children} de providers utiles

import {SynchronisationProvider} from "../../../Providers/synchronisation.provider";
import {OnlinestatequeryableProvider} from "../../../Providers/onlinestatequeryable.provider";
import {Toaster} from "react-hot-toast";
import {SynchronizationStackProvider} from "../../../Providers/SynchronizationStack.provider";
import {GlobalSynchronizationProvider} from "../../../Providers/SynchronizationGlobalProvider";

const Enveloppe = ({children}) => {
    return (
        <GlobalSynchronizationProvider>
            {/*<SynchronisationProvider>*/}
              {/*<SynchronizationStackProvider>*/}
                  <OnlinestatequeryableProvider>
                      {children}
                </OnlinestatequeryableProvider>
              {/*</SynchronizationStackProvider>*/}
            {/*</SynchronisationProvider>*/}
        </GlobalSynchronizationProvider>
    );
};

export { Enveloppe };