import {DateComponent} from "../../Presentation/Global/DateComponent";
import {Carte as CarteComponent} from '../../../../Bibliotheque/Composants/Presentation/Carte';
import {transformDate} from "../../../../Services/generic.helpers";

const AfficheurEvenementAgendaBasique = ({evenement_agenda}) => {

    const oneLineClass = "col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4";

    // const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    // const date_debut_string = evenement_agenda.date_debut.match(regex)[0];
    // const date_fin_string = evenement_agenda.date_fin.match(regex)[0];
    const date_debut = transformDate(evenement_agenda.date_debut);
    const date_fin = transformDate(evenement_agenda.date_fin);

    const lat = evenement_agenda?.lieu?.lat ?? undefined;
    const lon = evenement_agenda?.lieu?.lon ?? undefined;
    const adresse = evenement_agenda?.lieu?.adresse ?? undefined;
    const nom =  evenement_agenda?.lieu?.nom ?? undefined;

    const position = (lat && lon) ? [lat, lon] : undefined;

    return (
        <>
            <div className={`${oneLineClass}`}>
                <DateComponent date_debut={date_debut} date_fin={date_fin} />
            </div>
            {
                nom ?
                    (
                        <div className={`${oneLineClass}`}>
                            <span className={`text-xl text-primary-content font-extrabold`}>{nom}</span>
                        </div>
                    ) : (<></>)
            }
            {
                adresse ?
                    (
                        <div className={`${oneLineClass}`}>
                            <span className={`text-lg text-primary-content font-semibold`}>{adresse}</span>
                        </div>
                    ) : (<></>)
            }
            {
                position ?
                    (
                        <div className={`${oneLineClass}`}>
                            <CarteComponent position={position}/>
                        </div>
                    ) : (<></>)
            }
            {/*<div className={`${oneLineClass}`}>*/}
            {/*    <CarteComponent position={position} />*/}
            {/*</div>*/}
        </>
    );
}

export {AfficheurEvenementAgendaBasique}