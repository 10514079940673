/**
 * src/Services/Chargeurs/chargeur.ownspace.js
 *
 * Charge les données aggrégées pour affichage sur la page personelle
 */
import {
    getEvenementId, getIdentifiedRefreshToken, getIdentifiedUserToken,
    setIdentifiedRefreshToken, setIdentifiedUserToken
} from "../storage.service";
import {
    net_fetch_animations,
    net_refresh_token,
    net_fetch_myhome
} from "../network.action.service";
import {db, update_ownspace } from "../database.service";
import {connect_app} from "../connecteur.app";
import {AccessDeniedError} from "../../Exceptions/AccessDeniedError";

const charge_ownspace = async (evenement_id) => {
    if ( ! evenement_id ){
        evenement_id = getEvenementId();
    }

    let identified_usertoken = getIdentifiedUserToken();
    let ownspace_response;

    // ajouter une redirection vers la page
    if ( !identified_usertoken ){
        throw new AccessDeniedError('/login');
    }

    if ( navigator.onLine ){
        //
        ownspace_response = await net_fetch_myhome(evenement_id,identified_usertoken);
        // si on est en 401, on refresh et on rejoue la requête
        if ( 401 === ownspace_response.status ){
            // on refresh et on rejoue la requête
            let refresh_token = getIdentifiedRefreshToken();
            let refresh_response = await net_refresh_token(refresh_token);

            if( refresh_response.ok ){
                setIdentifiedUserToken(refresh_response.data.token);
                setIdentifiedRefreshToken(refresh_response.data.refresh_token);
            }else{
                throw new AccessDeniedError('/login');
            }

            identified_usertoken = getIdentifiedUserToken();
            ownspace_response = await net_fetch_myhome(evenement_id,identified_usertoken);
        }
        if ( ! ownspace_response.ok ){
            // ni 401, ni ok
            return {}
        }
        if ( db ){
            // on stocke la donnée dans la base de données
            return new Promise( (resolve, reject) => {
                update_ownspace(db,evenement_id,ownspace_response.data)
                    .catch( err => console.log(err) )
                    .finally( () => {
                        let data_to_resolve = ownspace_response.data;
                        resolve(data_to_resolve)});
            });
        }else{
            return ownspace_response.data;
        }
    }else{
        // si db on get db
        // sinon on laisse le fetch partir et être récupéré par le cache du sw
        if ( db ){
            return new Promise( (resolve,  reject) => {
                db.ownspace.where({evenement_id: evenement_id}).first()
                    .then( (ownspace_item) => {
                        let data = ownspace_item.ownspace;
                        resolve(data);
                    });
            });
        }else{
            // tente un service worker cache hit
            ownspace_response = await net_fetch_myhome(evenement_id,identified_usertoken);

            if ( ! ownspace_response.ok ){
                return {};
            }

            return ownspace_response.data;
        }
    }
};

export { charge_ownspace };