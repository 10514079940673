import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(I18NextHttpBackend)
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(I18nextBrowserLanguageDetector)
	.use(initReactI18next)
 .init({
	 load: 'languageOnly',
	 fallbackLng: 'fr',
	 debug: false,
	 interpolation: {
		 escapeValue: false
	 }
 });

export default i18n;

