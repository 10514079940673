const Bouton = ({lien, text}) => {
    return (
        <div className={`flex w-full max-w-3xl flex justify-start`}>
            <a className={``} href={lien}  >
                <button className={`btn btn-xs btn-primary`}>{text}</button>
            </a>
        </div>
    );
}

export {Bouton}