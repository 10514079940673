import {IMAGE_URL} from "../../../Config";
import {useTranslation} from "react-i18next";


// sélecteur animation, ou évaluations ou rien
// si évaluation, quel cas ?
const PuceEvenementAgenda = ({evenement_agenda}) => {

    const {i18n} = useTranslation();

    const evaluations = evenement_agenda.evaluations;
    const animation_id = evenement_agenda?.animation?.id ?? undefined;
    const evaluation = (evaluations && evaluations.length) && ( 1 === evaluations[0].length ) ? evaluations[0] : undefined;

    let animation_url = animation_id ? `/animation/${animation_id}` : undefined;
    let evaluation_url = evaluation ? `/questionnaire/${evaluation?.questionnaire?.id}/competiteur/${evaluation?.competiteur.id}` : '#';
    let link_url = `/evenement-agenda/${evenement_agenda.id}`;

    const display_nom_evenement = (evenement_agenda) => {
        return evenement_agenda?.translatedNom[i18n.language] ?? evenement_agenda?.nom ?? "Nom évènement";
    }

    const displayHeure = (sDatetime,timzone) =>{

        const date_heure =  sDatetime.split('T');
        const heure_minute_secondes_tz = date_heure[1].split(':');
        const string_to_return = `${heure_minute_secondes_tz[0]}:${heure_minute_secondes_tz[1]}`;

        return string_to_return;
    }

    // différent cas
    // vaudrait le coup de reprendre un factory et d'interfacer avec un adaptateur
    // si c'est la finale, le link est un lien vers le questionnaire

    if (evaluations.length > 1 && evaluations[0].questionnaire?.presentation === 'finale'){
        link_url = `/questionnaire/${evaluations[0].questionnaire.id}`;
        return (
            <a href={link_url}
               className={`flex justify-start items-start border border-accent rounded-md w-full col-span-1 max-h-[107px] bg-base-300 text-base-content`}
            >
                <figure className={`h-[100%] w-[100px] bg-neutral`}>
                    <img src={`${IMAGE_URL}/${evenement_agenda?.media?.path ?? "#"}`}
                         className={`object-cover w-full h-full`}/>
                </figure>
                <div className={`flex flex-col p-2 justify-around items-start h-full`}>
                    <span
                        className={`text-base font-normal flex-grow`}>{display_nom_evenement(evenement_agenda)}</span>
                    <span className={`w-full flex flex-row justify-between items-end`}>
                                    <span className={`text-sm`}>{evenement_agenda?.lieu?.nom ?? "nom lieu"}</span>
                                    <span
                                        className={`text-sm`}>{displayHeure(evenement_agenda.date_debut, evenement_agenda.date_debut_tz)}</span>
                                </span>
                </div>
            </a>
        );
    }
    // une évaluation ou une animation définie avec ordre animation puis évaluation dans le cas multi set
    if (animation_id || evaluation){
        link_url = animation_id ? animation_url : evaluation_url;
        return (
            <a href={link_url}
               className={`flex justify-start items-start border border-accent rounded-md w-full col-span-1 max-h-[107px] bg-base-300 text-base-content`}
            >
                <figure className={`h-[100%] w-[100px] bg-neutral`}>
                    <img src={`${IMAGE_URL}/${evenement_agenda?.media?.path ?? "#"}`}
                         className={`object-cover w-full h-full`}/>
                </figure>
                <div className={`flex flex-col p-2 justify-around items-start h-full`}>
                    <span
                        className={`text-base font-normal flex-grow`}>{display_nom_evenement(evenement_agenda)}</span>
                    <span className={`w-full flex flex-row justify-between items-end`}>
                                    <span className={`text-sm`}>{evenement_agenda?.lieu?.nom ?? "nom lieu"}</span>
                                    <span
                                        className={`text-sm`}>{displayHeure(evenement_agenda.date_debut, evenement_agenda.date_debut_tz)}</span>
                                </span>
                </div>
            </a>
        );
    }

    // si plusieurs évaluations, on voit si c'est simple de les afficher

    // if (evaluations?.length && evaluations.length >= 2){
    //     const usecase_liste_competiteur = evaluations.reduce( (carry, evaluation) =>
    //     {
    //         return {
    //             result: carry.result && ( carry.questionnaire_id === evaluation.questionnaire.id ),
    //             questionnaire_id: evaluation.questionnaire.id
    //         };
    //     }, {result: true, questionnaire_id: evenement_agenda.evaluations[0].questionnaire.id});
    //
    //     // le même compétiteur => catégorie différente
    //     const usecase_liste_categorie = evenement_agenda.evaluations.reduce( (carry, evaluation) => {
    //         return {
    //             result: carry.result && (carry.competiteur_name === evaluation.competiteur.instance_name),
    //             competiteur_name: evaluation.competiteur.instance_name
    //         }
    //     }, {result: true, competiteur_name: evenement_agenda.evaluations[0].competiteur.instance_name});
    //
    //     const display_questionnaire_type_name = (evaluation) => {
    //         if (usecase_liste_categorie.result){
    //             let translatedNoms = evaluation?.questionnaire?.type_evaluable?.translatedNom;
    //             return translatedNoms[i18n.language];
    //         }
    //         return evaluation?.competiteur?.instance_name;
    //     }
    //
    //
    //     if (usecase_liste_categorie.result || usecase_liste_competiteur.result){
    //         return (
    //             <div
    //                 className={`collapse collapse-plus col-span-1 border border-accent rounded-md bg-base-300 text-base-content`}>
    //                 <input type={`checkbox`}/>
    //                 <div className={`collapse-title pt-0 pl-0 pb-0 flex flex-col`}>
    //                     <div className={`flex justify-start items-start w-full `}>
    //                         <figure className={`h-[100px] w-[100px] bg-neutral`}>
    //                             <img src={`${IMAGE_URL}/${evenement_agenda?.media?.path ?? "#"}`}
    //                                  className={`object-cover w-full h-full`}/>
    //                         </figure>
    //                         <div className={`flex flex-col p-2 justify-around items-start h-full`}>
    //                     <span
    //                         className={`text-base font-normal flex-grow`}>{evenement_agenda?.nom ?? "nom évènement"}</span>
    //                             <span className={`w-full flex flex-row justify-between items-end`}>
    //                     <span className={`text-sm`}>{evenement_agenda?.lieu?.nom ?? "nom lieu"}</span>
    //                     <span
    //                         className={`text-sm`}>{displayHeure(evenement_agenda.date_debut, evenement_agenda.date_debut_tz)}</span>
    //                     </span>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className={`collapse-content flex flex-col items-start p-0 bg-base-300 text-base-content`}>
    //                     {
    //                         evenement_agenda?.evaluations?.map( (evaluation, i) =>
    //                             (
    //                                 <div className={`flex flex-row gap-1 items-center text-base-content`} key={i}>
    //                                     <a className={`btn btn-link text-base-content`} href={`/questionnaire/${evaluation?.questionnaire?.id}/competiteur/${evaluation?.competiteur?.id}`}>
    //                                         {display_questionnaire_type_name(evaluation)}
    //                                     </a>
    //                                     {evaluation?.complete && (<span className={`text-success`}>&#10004;</span>)}
    //                                 </div>
    //                             ))
    //                     }
    //                 </div>
    //             </div>
    //         );
    //     }
    // }

    // sinon, c'est compliqué, on affiche un lien vers l'évènement agenda
    return (
        <a href={link_url}
           className={`flex justify-start items-start border border-accent rounded-md w-full col-span-1 max-h-[107px] bg-base-300 text-base-content`}
        >
            <figure className={`h-[100%] w-[100px] bg-neutral`}>
                <img src={`${IMAGE_URL}/${evenement_agenda?.media?.path ?? "#"}`}
                     className={`object-cover w-full h-full`}/>
            </figure>
            <div className={`flex flex-col p-2 justify-around items-start h-full grow`}>
                    <span
                        className={`text-base font-normal flex-grow`}>{display_nom_evenement(evenement_agenda)}</span>
                <span className={`w-full flex flex-row justify-between items-end`}>
                                    <span className={`text-sm`}>{evenement_agenda?.lieu?.nom ?? "nom lieu"}</span>
                                    <span
                                        className={`text-sm`}>{displayHeure(evenement_agenda.date_debut, evenement_agenda.date_debut_tz)}</span>
                                </span>
            </div>
        </a>
    );

}

export { PuceEvenementAgenda }