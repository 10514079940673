class MessageCarrierError extends Error
{
    constructor(message_string, message_type){
        super();
        this.message_type = message_type;
        this.message_string = message_string;
    }

}

export {MessageCarrierError};