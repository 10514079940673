import { useState } from "react";

import {DayPicker} from "react-day-picker";
import "react-day-picker/style.css";
import { enUS , fr } from "date-fns/locale";
import {useTranslation} from "react-i18next";

import '../../Assets/scss/_date_picker_overrides.scss';
import {useNavigate} from "react-router-dom";

const DateSelector = (props) => {

    const {selected_date} = props;

    const [selected, setSelected] = useState(selected_date);

    const {i18n,t} = useTranslation();

    const handleSelected = (date_value) => {
        let year = (''+date_value.getFullYear()).padStart(4,'0');
        let month = (''+(date_value.getMonth() + 1)).padStart(2,'0');
        let day = (''+date_value.getDate()).padStart(2,'0');

        const formatted_date_string = `${year}${month}${day}`;

        window.location.href = `/agenda/${formatted_date_string}`;
    }
    const handleLocaleChange = () => {
        switch(i18n.language){
            case 'en':
                return enUS;
            case 'fr':
                return fr;
        }
    }

    return (
        <section className={`mx-auto flex flex-col`}>
            <div className={`mt-4`}>
                <DayPicker
                    locale={handleLocaleChange()}
                    mode="single"
                    selected={selected}
                    onSelect={handleSelected}
                    defaultMonth={selected_date}
                />
            </div>
        </section>
    )
}

export {DateSelector};