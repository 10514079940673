import {useTranslation} from "react-i18next";
import {DateSelector} from "./DateSelector";
import {Await, useLoaderData} from "react-router-dom";
import {Suspense} from "react";
import LoadingComponent from "../../../lespapillons/Elements/loading.component";
import ListeEvenementAgendaAujourdhui from "../../Components/ListeEvenementAgendaAujourdhui";
import {CalendarDatePicker} from "../../../../Bibliotheque/Composants/Presentation/CalendarDatePicker";

const Agenda = () => {

    const { t, i18n } = useTranslation();
    const { data } = useLoaderData();

    const oneLineClass = "col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4";

    const extract_date_from_url = () => {
        let date_to_return = new Date();
        let matches = window.location.href.match(/^.*\/agenda\/(\d{8})$/);

        if (matches && matches[1]){
            const year = parseInt(matches[1].substring(0,4));
            const month = parseInt(matches[1].substring(4,6))-1;
            const day = parseInt(matches[1].substring(6,8));
            date_to_return.setDate(1);
            date_to_return.setFullYear(year);
            date_to_return.setMonth(month);
            date_to_return.setDate(day);
            // date_to_return = Date.parse(`${year}-${month}-${day}T00:00:00`);
        }
        return date_to_return;
    };

    const selected_date = extract_date_from_url();

    return (
        <Suspense fallback={<LoadingComponent/>}>
            <Await resolve={data}>
                <div
                    className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 max-w-[2048px] mx-auto my-12 px-6 w-full">
                    <h1 className={`${oneLineClass} flex flex-col`}>
                        <span className={`text-2xl font-extrabold`}>{t('Agenda.h1', i18n.language)}</span>
                    </h1>
                    <div className={`${oneLineClass} mb-12`}>
                        <DateSelector selected_date={selected_date}/>
                    </div>
                    {/*<div className={`${oneLineClass} mb-12`}>*/}
                    {/*    <CalendarDatePicker selected_date={selected_date}/>*/}
                    {/*</div>*/}
                    <h2 className={`${oneLineClass} flex flex-col`}>
                        <span className={`text-xl font-semibold`}>{t('Agenda.h2', i18n.language)}</span>
                    </h2>
                    <ListeEvenementAgendaAujourdhui evenement_agendas={data}/>
                </div>
            </Await>
        </Suspense>
    );
}

export {Agenda};