const Banniere = (props) => {
    const {titre,className, bg_image} = props;
    return (
        <div className={`flex flex-col justify-center items-center relative h-80 overflow-hidden w-full`}>
            <figure className={`absolute top-0 left-0 w-full h-full `}>
                <img className="w-full object-cover h-full object-center object-top" src={bg_image} alt={` Festival Saveurs du monde`}/>
            </figure>
            <div className={`absolute top-0 left-0 w-full h-full bg-primary opacity-30`}>
            </div>
            <h1 className={`px-4 text-primary-content relative ${className} text-3xl md:text-5xl drop-shadow-lg font-sans font-extrabold text-center`}>{titre}</h1>
        </div>
    );
}

export { Banniere };