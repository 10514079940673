import axios from "axios";
import { API_URL } from "../Config";
import { auth_refreshToken } from "./auth.service";
import fetchAdapter from "./axios.fetch.adapter";

// const axiosConfig =

const axiosFetch = axios.create({
	baseURL: `${API_URL}/`,
	headers: {
		"Content-Type": "application/json"
	},
	withCredentials: false,
	_retry: false,
	adapter: fetchAdapter,
});

const interceptor_callbacks = {
	request_interceptor__add_auth_header_to_request: async (config) => {
		const user_token = localStorage.getItem('user_token');
		if ( user_token ){
			config.headers['Authorization'] = ` Bearer ${user_token}`;
		}
		return config;
	},
	request_interceptor__add_auth_header_to_request__fallback: (error) => {
		return Promise.reject(error);
	},
	response_interceptor__response_200_passthrough: (response) => response,
	response_interceptor__response_401_retry: async (error) => {
		// bug de passage de paramètre dans la bibliothèque axios
		// https://github.com/axios/axios/issues/2295
		const _401_retry = parseInt( localStorage.getItem('_401_retry') )
		if ( error.response.status === 401 && _401_retry > 0){
			localStorage.setItem('_401_retry', '0');

			await auth_refreshToken();
			return axiosFetch(error.config);
		}
		return Promise.reject(error);
	}
}

const interceptor_reference__add_header = axiosFetch.interceptors.request.use(
	interceptor_callbacks.request_interceptor__add_auth_header_to_request,
	interceptor_callbacks.request_interceptor__add_auth_header_to_request__fallback
);

const interceptor_reference__handle_401 = axiosFetch.interceptors.response.use(
	interceptor_callbacks.response_interceptor__response_200_passthrough,
	interceptor_callbacks.response_interceptor__response_401_retry
);

const interceptor_references = {
	add_auth_header: interceptor_reference__add_header,
	handle_401: interceptor_reference__handle_401,
}

const axiosInstance = () => {
	const token = localStorage.getItem("user_token");

	if (token){
		return { Authorization: `Bearer ${token}`};
	}else{
		return {};
	}
}

export {
	axiosFetch,
	axiosInstance,
	interceptor_callbacks,
	interceptor_reference__add_header,
	interceptor_reference__handle_401,
	interceptor_references
};
