import {Competiteur} from "./Competiteur";

const AfficheurEvaluationsPourEvenementAgenda = ({evenement_agenda}) => {
    let oCompetiteurs = evenement_agenda.evaluations.reduce(
        (carry, evaluation) => {
            const competiteur_name = evaluation.competiteur.instance_name;
            if ( undefined !== carry[competiteur_name] ){
                carry[competiteur_name].push(evaluation);
            }else{
                carry[competiteur_name] = [evaluation];
            }
            return carry;
        }, {});

    let competiteurs = [];
    for ( const competiteur_name in oCompetiteurs ){
        competiteurs.push(
            {
                competiteur_name: competiteur_name,
                evaluations: oCompetiteurs[competiteur_name]
            }
        )
    }


    return (
        <>{
            competiteurs.map( (competiteur,i) =>
                <Competiteur key={i} competiteur={competiteur}/>
            )}
        </>
    );
}

export {AfficheurEvaluationsPourEvenementAgenda};