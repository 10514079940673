const TypeZoneTexte = ({dispatcher, sectionNumber, questionNumber, valeur, error, ...the_rest}) => {
    const handleChange = (event) => {
        let valeur = event.currentTarget.value;

        dispatcher({
            type: 'setValeur',
            payload: {
                valeur: valeur,
                section_number: sectionNumber,
                question_number: questionNumber
            }
        })
    }

    return (
        <textarea name={`q-${questionNumber}`}
                  className={`text-neutral-content bg-neutral input-question-texte p-2 my-2 rounded-lg ${error? 'error' : ''}`}
                  onChange={handleChange}
                  value={`${valeur ?? ''}`}>
        </textarea>
    );
}

export {TypeZoneTexte}