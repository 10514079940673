import '../../../css/_modale_evaluation.scss';

const TypeTexte = ({dispatcher, sectionNumber, questionNumber, valeur, error, ...the_rest}) => {

    const {presentation} = the_rest;

    const handleChange = (event) => {
        let value = event.currentTarget.value;

        dispatcher({
            type: 'setValeur',
            payload: {
                valeur: value,
                section_number: sectionNumber,
                question_number: questionNumber
            }
        })
    }

    return (
        <input type={`text`} name={`q-${questionNumber}`}
               className={`text-neutral-content bg-neutral input-question-texte px-2 my-2 rounded-lg ${error? 'error' : ''}`}
               value={valeur ?? ''}
               onChange={handleChange}
        />
    );
}

export {TypeTexte}