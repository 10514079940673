import {Banner} from "../../Components/Banner";
import img_vegan from "../../Assets/Image/20240515_vegan2.jpeg";
import {Await, useLoaderData} from "react-router-dom";
import {Suspense} from "react";
import LoadingComponent from "../../../../Components/Elements/loading.component";
import {IMAGE_URL} from "../../../../Config";
import {DescriptionParser} from "../../../../Components/Presentation/DescriptionParser";
import {Description} from "../../../../Components/Presentation/Description";
import {OverridingMapping} from "../../Presentation/ComposantMapping";

// faire un mécanisme pour gérer la surcharge de descriptions afin de changer les composants
// requis par thèmes.

const data1 = {
    titre: "Titre de l'animation injecté depuis le chargeur",
    images: [img_vegan]
}


// !TODO mettre le parser fallback dans propre structure logique
const fallback_parser = (composant_name,composant_data,animation_data, key) => {
    return (<div key={key}>{`Composant ${composant_name} introuvable, construisez-en un !`}</div>);
}

const Animation = () => {

    const loaded = useLoaderData();

    return (
        <Suspense fallback={<LoadingComponent/>}>
            <Await resolve={loaded.data}>
                {
                    (data) => {

                        return (
                            <div className={`flex flex-col items-center justify-center w-full space-y-10 mx-auto`}>
                                <Description projection={data} fallback_parser={fallback_parser} overridenMapping={OverridingMapping}/>
                            </div>
                        );
                    }
                }
            </Await>
        </Suspense>
    );
};

export {Animation};