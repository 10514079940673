import {createContext, useContext, useEffect, useState} from "react";
import {useSyncToServer} from "./SynchronizationGlobalProvider";

const OnlineStateQueryableContext = createContext();

const OnlinestatequeryableProvider = ({children}) => {

	const [ isOnline, setIsOnline ] = useState(navigator.onLine);
	const { setSyncFlag } = useSyncToServer();

	const listenToOnlineEvent = (event) => {
		if ( ! isOnline ){
			setIsOnline(true);
			setSyncFlag(true);
		}
	};

	const listenToOfflineEvent = (event) => {
		setIsOnline(false);
		setSyncFlag(false);
	};

	useEffect( () => {
		window.removeEventListener('online', listenToOnlineEvent);
		window.removeEventListener('offline', listenToOfflineEvent);
		window.addEventListener('online', listenToOnlineEvent);
		window.addEventListener('offline', listenToOfflineEvent);
	});

	return (
		<OnlineStateQueryableContext.Provider value={{isOnline}}>
			{children}
		</OnlineStateQueryableContext.Provider>
	)
};

const useOnlineState = () => {
	return useContext(OnlineStateQueryableContext);
}

export { useOnlineState, OnlinestatequeryableProvider };
