import {PuceEvaluation} from "./PuceEvaluation";
import {useTranslation} from "react-i18next";

const Competiteur = (props) => {
    const {competiteur} = props;
    const {competiteur_name, evaluations} = competiteur;

    const {i18n} = useTranslation();

    let evaluations_to_map = [];
    if (evaluations && evaluations.length && evaluations.length > 1) {
        // on classe les catégories par ordre alphabétique
        let _evaluations = evaluations.sort( (e1,e2) => {
            return e1.questionnaire.type_evaluable.translatedNom[i18n.language] <
                e2.questionnaire.type_evaluable.translatedNom[i18n.language];
        } )
        evaluations_to_map = _evaluations.map( evaluation => {
           return {
               titre: evaluation.questionnaire.type_evaluable.translatedNom[i18n.language],
               evaluation: evaluation
           };
        });
    }

    if (evaluations && evaluations.length && evaluations.length === 1 ){
        evaluations_to_map.push({
            titre: evaluations[0]?.lieu?.nom?? 'titre',
            evaluation: evaluations[0]
        });
    }

    const oneLineClass = "col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4";

    return (
        <>
            <h2 className={`${oneLineClass} font-semibold text-lg`}>{competiteur_name}</h2>
            {
                evaluations_to_map.map( (evaluation, index) => {
                    return (<PuceEvaluation evaluation={evaluation.evaluation} key={index} titre={evaluation.titre}/>);
                })
            }
        </>
    );
}

export {Competiteur};