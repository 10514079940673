function urlBase64ToUint8Array(base64String) {
	var padding = '='.repeat((4 - base64String.length % 4) % 4);
	var base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	var rawData = window.atob(base64);
	var outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

const subscribeUserToPushService = async (registration) => {
	const subscribeOptions = {
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array('BIOf-Mc8NmNoKHfUmy6MHHWLMrutiTPY4nYcLm1YcOa_lF_zD_gJLoBrYiBPKt-P4S4LWAfJ23_SasRqEC2Qg_8')
	};
	return registration.pushManager.subscribe(subscribeOptions);
}

const registerServiceWorker = async () => {
	if ( "serviceWorker" in navigator ){

		try{
			let registration = await navigator.serviceWorker.register('/service-worker.js',{scope: '/'});
			// let subscription = subscribeUserToPushService(registration);
			// subscription.then( (sub) => {
			// 	console.log(sub);
			// })
		}catch(error){
			console.log("Erreur au register service worker.");
		}
		// const registration = navigator.serviceWorker.register('/service-worker.js',{scope: '/'})
		// 	.then( (registration) => {
		// 		return registration;
		// 	}).catch( (rejected_reason) => {
		// 		console.error("registration of service worker failed : ", rejected_reason);
		// 		return null;
		// })

	}
}

export default registerServiceWorker;
