import axios from "axios";
import { axiosFetch } from './axiosInstance';
import {getAnonymousUserToken, getIdentifiedUserToken} from "./storage.service";
import {API_URL, APP_PWD, APP_USER} from "../Config";
import {net_login, net_refresh, USER_TYPE_ANONYMOUS} from "./network.action.service";

// Les loader de react router dom nécessite des promises, on les force.

// échanges relatifs aux questionnaires :

// const user_refresh_auth = () => fetch( 'http://dev.app.questionnaire.scr/api/token/refresh', {
// 	headers: {
// 		"Content-Type": "application/json",
// 	},
// 	body: {
// 		"refresh_token": localStorage.getItem('refresh_token')
// 	},
// 	method: 'POST',
// });

const setupRetry = () => {
	localStorage.setItem('_401_retry','1');
}

// const data_getQuestionnaires_2 = () => {
// 	// si identifiant
// 	// envoi avec bearer
// 	if ( user_token ){
// 		axiosFetch.get('questionnaires' with bearer)
// 			.then( (ok) => return data);
// 			.catch( err => si 403
// 				return refresh_token_2().then(
// 					(ok) => axiosFetch.get('questionnaires' with bearer)
// 								.then ( ok => renvoie données )
// 								.catch( err => naviguer login )
// 				)
//
// 	)
//
// 	}elseif( anonymous_token ){
//
// 	}
//
// }

const execute_request = async (request_to_execute, n_retry, ...args1) => {
	// on séquence par privilège
	// si user_token est spécifié, c'est un utilisateur enregistré
	// si impossible de se connecter , peut-être fake, test refresh
	// si fake pas refresh, pas token
	// mais peut aussi être vrai et problème et donc il faut login
	// mais pour

	let user_token = getIdentifiedUserToken();
	// partie utilisateur identifié
	if ( user_token ){
		while( n_retry > 0)
		{
			try{
				let r = await request_to_execute(user_token, ...args1);
				return r;
			}catch(e){
				// en fonction du code de l'erreur
				console.log(e);
				// si 401, on retente
				n_retry -= 1;
			}
		}
		throw new Error('login');
	}
	// partie utilisateur anonyme
	let anonymous_user_token = getAnonymousUserToken();
	if ( anonymous_user_token ){
		while( n_retry > 0)
		{
			try{
				let r = await request_to_execute(anonymous_user_token, ...args1);
				return r;
			}catch(e){
				// en fonction du code de l'erreur
				console.log(e);
				// si 401, on retente
				n_retry -= 1;
			}
		}
	}
	// on part sur la séquence de connexion de l'app
	throw new Error('connect');
}

const get_evenement_request = async (user_token, evenement_id) => {
	console.log(evenement_id);
	try{
		const response = await axios({
			url: `${API_URL}/evenement/${evenement_id}`,
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
				"Authorization": ` Bearer: ${user_token}`,
			},
		});
		return response.data;
	}catch(e){
		// si erreur unauthorized :
		throw(e);
	}
}

const get_questionnaire_request = async (user_token, ...args1) => {
	try{
		const response = await axios({
			url: `${API_URL}/questionnaires`,
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
				"Authorization": ` Bearer: ${user_token}`,
			},
		});
		return response.data;
	}catch(e){
		// si erreur unauthorized :
		throw(e);
	}
}



const data_getQuestionnaires = () => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get('questionnaires')
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

// on attaque les évaluations pour récupérer les questionnaires associés
// inversion métier, pour le répondant une évaluation est un questionnaire,
// pour le créateur de questionnaire c'est la partie question.

// !TODO peut-être changer le endpoint pour quelque chose de moins abrasif, voir la récupération des évaluations plus loin.

const data_getQuestionnaire = (questionnaireId) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get(`evaluations/${questionnaireId}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) );
	});
}

// échanges relatives aux évaluations :

const data_getEvaluationsParTypeEvaluable = (type_evaluable_id) => {
	setupRetry();
	return new Promise( (resolve,reject) => {
		axiosFetch.get(`evaluations/filter/type_evaluable/${type_evaluable_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_getEvaluationsParQuestionnaireId = (questionnaire_id) => {
	setupRetry();
	return new Promise( (resolve,reject) => {
		axiosFetch.get(`evaluations/filter/questionnnaire/${questionnaire_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_getEvaluation = (evaluation_id) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get(`evaluation/${evaluation_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_updateEvaluation = (clef,valeur, evaluation_id) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		let data = {};
		data[clef] = valeur;

		axiosFetch.put(`evaluation/${evaluation_id}`, data)
			.then( data => resolve(data) )
			.catch( error => reject(data) )
	})
}

const data_getAgenda = () => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.get("/agenda")
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_getFullAgenda = () => {
	setupRetry();
	return new Promise( (resolve,reject) => {
		axiosFetch.get("/eva-full-agenda")
		// axiosFetch.get("/full-agenda")
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	})
}

const data_getEvenements = () => {
	return new Promise( (resolve, reject) => {
		axiosFetch.get("/evenement")
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	})
}

const data_getEvenement = (evenement_id) => {
	return new Promise( (resolve, reject) => {
		axiosFetch.get(`/evenement/${evenement_id}`)
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	})
}

// échanges relatifs aux réponses :
// c'est ici ou la sémantique devient tordue ^^' :
//  on veut répondre à une question, donc créer ou mettre à jour une réponse.
// plutôt que de détecter la présence ou non d'une réponse avec un id pour pousser vers
// le bon endpoint avec la bonne méthode et les bons paramètres
// on envoie sur le serveur la sémantique du répondant et la logique métier codée côté serveur fait le reste.

// !TODO voir à long terme les implications en terme d'évolutions pour changer éventuellement.

const data_createOrUpdateReponsePourEvaluation = ( question_id, valeur, evaluation_id ) => {
	setupRetry();
	return new Promise( (resolve, reject) => {
		axiosFetch.put(`reponse/${question_id}`, {
			valeur: valeur,
			evaluation_id: evaluation_id,
		})
			.then( data => resolve(data) )
			.catch( error => reject(error) )
	});
}

const data_synchronizeDisplayedSection = ( jsSection ) => {
	setupRetry();
	return axiosFetch.put(`synchronizesection`, { data: jsSection } );
}

const data_createUserForApp = ( ) => {
	return new Promise( (resolve, reject) => {
		axiosFetch.get('connect')
			.then((response) => resolve(response))
			.catch( error => reject(error))
	});
}

const data_connectApp = async () => {
	await net_login(APP_USER,APP_PWD,USER_TYPE_ANONYMOUS);
	const app_token = getAnonymousUserToken();
	return axios({
		url: `${API_URL}/connect`,
		method: 'POST',
		headers: {
			"Content-Type": "application/json",
			"Authorization": ` Bearer ${app_token}`
		}
	});
}

class DataService{
}

const dataService = new DataService();

export { dataService,
	data_getQuestionnaires,
	data_getQuestionnaire,
	data_getEvaluationsParTypeEvaluable,
	data_getEvaluation,
	data_getEvaluationsParQuestionnaireId,
	data_createOrUpdateReponsePourEvaluation,
	data_updateEvaluation,
	data_getAgenda,
	data_getFullAgenda,
	data_synchronizeDisplayedSection,
	data_getEvenements,
	data_getEvenement,
	data_createUserForApp,

	get_questionnaire_request,
	execute_request,
	get_evenement_request,
	data_connectApp,
};
