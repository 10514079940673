class DTOStack {
    constructor(){
        this.stack = new Map();
        this.history = [];
    }

    computeKey(dto){
        return `q${dto.questionnaire_id}:e${dto.competiteur_id}:s${dto.current_section}`;
    }
    contains(dto){
        if ( this.empty ){
            return false;
        }
        let key = this.computeKey(dto);
        return -1 !== this.history.indexOf(key);
    }

    push(dto){
        let key = this.computeKey(dto);

        if ( this.contains(dto) ){
            this.history.splice(this.history.indexOf(key),1);
            this.stack.delete(key);
        }
        this.history.push(key);
        this.stack.set(key,dto);
    }

    pop() {
        if ( this.empty ){
            return undefined;
        }
        let key = this.history.pop();
        let dto = this.stack.get(key);
        this.stack.delete(key);
        return dto;
    }

    peek() {
        if ( this.empty ){
            return undefined;
        }

        return this.stack.get(this.history.at(-1));
    }

    serialize()
    {
        let o_stack = {};
        for( const[key, val] of this.stack.entries() ){
            o_stack[key] = val;
        }
        let objet_to_serialize = {
            history: this.history,
            stack: o_stack
        };

        return JSON.stringify(objet_to_serialize);
    }

    keysFor(questionnaire_id, instance_evaluable_id){
        const re = new RegExp(`q${questionnaire_id}:e${instance_evaluable_id}:s\\d+`,"g");
        let keys = this.history.filter( (k) => k.match(re) );
        return keys;
    }

    at(key){
        if (! this.stack.has(key)){
            return undefined;
        }
        return this.stack.get(key);
    }

    get length() {
        return this.history.length;
    }

    get empty() {
        return this.length === 0;
    }

}

class DTOStackFactory {
    build(serialized_stack){
        let returned_dto_stack = new DTOStack();
        if ( ! serialized_stack){
            return returned_dto_stack;
        }
        try{
            let unserialized_stack = JSON.parse(serialized_stack);
            for ( const key of unserialized_stack.history ){
                let dto = unserialized_stack.stack[key];
                returned_dto_stack.push(dto);
            }
        }catch(e){
            console.error("Error unserializing dto stack.");
        }

        return returned_dto_stack;
    }
}

class QuestionnaireStateFactory {
    buildFromQuestionnaireDTOs(questionnaireDTOs, initial_state = undefined){
        if ( ! initial_state ){
            initial_state = {
                questionnaire_data: {
                    sendBacks: {}
                }
            }
        }

        if ( Object.keys(questionnaireDTOs).length <= 0 ){
            return initial_state;
        }

        for ( const[key,dto] of Object.entries(questionnaireDTOs) ){
            let re = new RegExp(":s(\\d+)");
            let matches = key.match(re);
            let s = matches ? matches[1] : undefined;
            initial_state['questionnaire_data']['id'] = dto.questionnaire_id;
            initial_state['questionnaire_data']['sections'][s] = dto.sections[s];
            initial_state['questionnaire_data']['sendBacks']['query__evaluable_id'] = dto.instance_type_evaluable_id;
            initial_state['questionnaire_data']['sendBacks']['query__competiteur_id'] = dto.competiteur_id;
        }

        // initial_state['questionnaire_data']['id'] = questionnaireDTO.questionnaire_id;
        // initial_state['questionnaire_data']['sections'] = questionnaireDTO.sections;
        // initial_state['questionnaire_data']['sendBacks']['query__evaluable_id'] = questionnaireDTO.instance_type_evaluable_id;

        return initial_state;
    }
}

export {DTOStack, DTOStackFactory, QuestionnaireStateFactory}