import {getThemeValue} from "../../../../Services/storage.service";

const Bouton = ({lien, text, cible}) => {

    return (
        <div className={`flex w-full max-w-3xl m-auto justify-center`}>
            <a className={``} href={lien} target={cible === '_blank' ? '_blank': false}>
                <button className={`btn bg-primary-content text-primary border-primary-content text-lg hover:bg-accent hover:border-accent hover:text-neutral-content font-normal font-sans`}>{text}</button>
            </a>
        </div>
    );
}

export {Bouton}