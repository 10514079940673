import {Timeline} from "../../Components/Timeline";

import video_src from "../../Assets/Videos/20240927_festival_sxm_2024.mp4";
import {Await, useLoaderData} from "react-router-dom";
import LoadingComponent from "../../../../Components/Elements/loading.component";
import {Suspense} from "react";
import {Video} from "../../Components/Video";

import '../../Assets/scss/_effets.scss';
import {useTranslation} from "react-i18next";

const Home = () => {

    const { t } = useTranslation();
    let current_query_string = () => {
        let url = new URL(window.location.href);
        let tags = url.searchParams.getAll("tag");
        if (tags && tags.length){
            console.log(tags);
        }
    }

    const loaded = useLoaderData();

    return (
        <Suspense fallback={<LoadingComponent/>}>
            <Await resolve={loaded.data}>
                {
                    (data) => {
                        return (
                            <div className={`flex flex-col items-center justify-center w-full mx-auto space-y-10`}>

                                <Video className={`text-4xl`} videoSrc={video_src}/>
                                <div className={`grid grid-cols-1 gap-10 mx-4 justify-items-center`}>
                                    <h1 className={`text-primary-content relative text-3xl md:text-5xl drop-shadow-lg font-sans font-extrabold flex flex-col items-center text-center`}>
                                        <span className={``}>{t('Page.Home.titre')}</span>
                                        <span className={`font-serif font-light italic md:text-6xl`}>2024</span>
                                    </h1>
                                    <span
                                        className={`top-0 px-10 max-w-3xl text-lg font-serif italic bg-accent text-accent-content font-light tracking-wider clip-path-parallelogram`}>
                                        {t('Page.Home.dureefestival')}
                                    </span>

                                    <section className={`w-full max-w-3xl`}>
                                        <Timeline animations={data.animations}/>
                                    </section>

                                </div>
                            </div>
                        );
                    }
                }
            </Await>
        </Suspense>
    );
}

export {
    Home
};