// à envoyer vers le serveur pour mise à jour
const build_evaluationDTO_from_questionnaire_state = (questionnaire_state) => {
    let evaluation_DTO_to_return = {
        questionnaire_id: questionnaire_state.questionnaire_data.id,
        sections: questionnaire_state.questionnaire_data.sections,
        instance_type_evaluable_id: questionnaire_state.questionnaire_data.sendBacks.query__evaluable_id,
        competiteur_id: parseInt(questionnaire_state.questionnaire_data.sendBacks.query__competiteur_id),
        current_section: questionnaire_state.current_section
    }

    return evaluation_DTO_to_return;
 };

// à utiliser dans le formulaire questionnaire pour affichage
const build_questionnaire_state_from_response = (response_data) => {

}

 export { build_evaluationDTO_from_questionnaire_state };