import {Navigation, Pagination, Scrollbar} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const SwiperComponent = ({slides_src}) => {
    return (
        <div className={`w-full max-w-3xl`}>
            <Swiper modules={[Navigation, Pagination, Scrollbar]}
                    slidesPerView={1}
                    navigation
                    pagination={{clickable: true}}
                    scrollbar={{draggable: true}}>
                {slides_src.map( (src, i) => {
                    return(
                        <SwiperSlide key={i} >
                            <figure className={`overflow-hidden`}>
                                <img className={`w-full h-full object-cover`} src={src} />
                            </figure>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}

export {SwiperComponent}