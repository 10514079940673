import {Questionnaire} from "../../../Components/Metier/Questionnaire/Questionnaire";
import {NomEvaluable} from "./NomEvaluable";

const ModaleEvaluation = ({affichageEvaluation, handleClose, ...props}) => {

    return (
        <div
            className={`absolute top-0 left-0 ${affichageEvaluation ? "translate-y-64" : "translate-y-[100vh]"} transition-transform ease-in-out duration-500 w-full modale-evaluation z-20`}>
            <div className={`w-full max-w-3xl mx-auto bg-base-200 rounded-t-2xl p-4 h-full flex flex-col overflow-auto`}>
                <div className={`flex flex-row gap-4 w-full`}>
                    <button className="btn btn-circle btn-primary" onClick={() => handleClose()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                    <NomEvaluable className={`col-start-1 col-end-3 text-primary font-normal`} nom_evaluable={``}/>
                </div>
                <Questionnaire />
            </div>
        </div>
    );
};

export { ModaleEvaluation }