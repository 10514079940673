// proto :

// import {set} from "react-hook-form";
import {APP_THEME} from "../Config";

class StorageService {
    static bag_name = `global_setting_${APP_THEME}`;

    static anonymous_email_key = 'anonymous_email';
    static anonymous_password_key = 'anonymous_password';
    static anonymous_refresh_token_key = 'anonymous_refresh_token';
    static anonymous_user_token_key = 'anonymous_user_token';

    static identified_user_token_key = 'identified_user_token';
    static identified_refresh_token_key = 'identified_refresh_token'

    static evenement_id_key = 'evenement_id';

    static url_to_redirect_to_key = 'redirection_url';

    static flash_message_key = 'flash_message';
    static flash_type_key = 'flash_type';

    static dto_stack_key = 'dto_stack';

    __get_key(key_name){
        let global_settings = localStorage.getItem(StorageService.bag_name);
        if ( ! global_settings ){
            return null;
        }
        let to_return = null;
        try{
            let o_global_settings = JSON.parse(global_settings);
            to_return = o_global_settings[key_name] && o_global_settings[key_name] !== "null" ?
                o_global_settings[key_name] : null;
        }catch(e){
            // to_return = null;
        }

        return to_return;
    }

    __set_key_value(key_name, value){
        let global_settings = localStorage.getItem(StorageService.bag_name);
        if ( ! global_settings ){
            global_settings = JSON.stringify({});
        }

        let o_global_settings = {};
        try{
            o_global_settings = JSON.parse(global_settings);
        }catch(e){
            // o_global_settings = JSON.stringify({});
        }
        o_global_settings[key_name] = value;
        localStorage.setItem(StorageService.bag_name,JSON.stringify(o_global_settings));
    }

    __remove_key(key_name) {
        let global_settings = localStorage.getItem(StorageService.bag_name);
        if ( ! global_settings ){
            global_settings = JSON.stringify({});
        }

        let o_global_settings = {};
        try{
            o_global_settings = JSON.parse(global_settings);
        }catch(e){
            // o_global_settings = JSON.stringify({});
        }
        let new_o_global_settings = Object.entries(o_global_settings)
            .filter( ([k_n,k_v]) => k_n !== key_name)
            .reduce( (carry, [k_n,k_v]) => { let n_state = {...carry}; n_state[k_n]=k_v; return n_state; } );

        localStorage.setItem(StorageService.bag_name, JSON.stringify(new_o_global_settings));
    }

    __is_key_set(key_name) {
        let global_settings = localStorage.getItem(StorageService.bag_name);
        if ( ! global_settings ){
            global_settings = JSON.stringify({});
        }

        let o_global_settings = {};
        try{
            o_global_settings = JSON.parse(global_settings);
        }catch(e){
            // o_global_settings = JSON.stringify({});
        }
        return -1 !== Object.keys(o_global_settings).indexOf(key_name);
    }
}

// partie anonyme
const setAnonymousUserEmail = (email) => (new StorageService().__set_key_value(StorageService.anonymous_email_key, email));
const setAnonymousUserPassword = (password) => (new StorageService().__set_key_value(StorageService.anonymous_password_key, password));
const getAnonymousUserEmail = () => (new StorageService().__get_key(StorageService.anonymous_email_key));
const getAnonymousUserPassword = () => (new StorageService().__get_key(StorageService.anonymous_password_key));
const getAnonymousRefreshToken = () => (new StorageService().__get_key(StorageService.anonymous_refresh_token_key));
const getAnonymousUserToken = () => (new StorageService().__get_key(StorageService.anonymous_user_token_key));
const setAnonymousUserToken = (anonymous_user_token) => (new StorageService().__set_key_value(StorageService.anonymous_user_token_key, anonymous_user_token));
const setAnonymousRefreshToken = (anonymous_refresh_token) => (new StorageService().__set_key_value(StorageService.anonymous_refresh_token_key, anonymous_refresh_token));

// partie identifié

const getIdentifiedUserToken = () => (new StorageService().__get_key(StorageService.identified_user_token_key));
const setIdentifiedUserToken = (user_token) => (new StorageService().__set_key_value(StorageService.identified_user_token_key, user_token));
const getIdentifiedRefreshToken = () => (new StorageService().__get_key(StorageService.identified_refresh_token_key));
const setIdentifiedRefreshToken = (refresh_token) => (new StorageService().__set_key_value(StorageService.identified_refresh_token_key, refresh_token));
const removeIdentifiedUserToken = () => (new StorageService().__remove_key(StorageService.identified_user_token_key));
const removeIdentifierRefreshToken = () => (new StorageService().__remove_key(StorageService.identified_refresh_token_key));


// commodities

const getRedirectionUrl = () => (new StorageService().__get_key(StorageService.url_to_redirect_to_key));
const setRedirectionUrl = (url) => (new StorageService().__set_key_value(StorageService.url_to_redirect_to_key, url));
const removeRedirectionUrl = () => (new StorageService().__remove_key(StorageService.url_to_redirect_to_key));
const isRedirectionUrlSet = () => (new StorageService().__is_key_set(StorageService.url_to_redirect_to_key));

const isFlashMessageSet = () => (new StorageService().__is_key_set(StorageService.flash_message_key));
const getFlashMessage = () => (new StorageService().__get_key(StorageService.flash_message_key));
const setFlashMessage = (message_string) => (new StorageService().__set_key_value(StorageService.flash_message_key, message_string));
const removeFlashMessage = () => (new StorageService().__remove_key(StorageService.flash_message_key));
const setFlashStyle = (message_style) => (new StorageService().__set_key_value(StorageService.flash_type_key,message_style));
const getFlashStyle = () => (new StorageService().__get_key(StorageService.flash_type_key));

const setDTOStack = (serialized_dto_stack) => (new StorageService().__set_key_value(StorageService.dto_stack_key, serialized_dto_stack));
const removeDTOStack = () => (new StorageService().__remove_key(StorageService.dto_stack_key));
const getDTOStack = () => (new StorageService().__get_key(StorageService.dto_stack_key));

const getEvenementId = () => (new StorageService().__get_key(StorageService.evenement_id_key));
const setEvenementId = (evenement_id) => (new StorageService().__set_key_value(StorageService.evenement_id_key, evenement_id));

// renvoie l'état de l'évaluation pour le restaurant dont l'id est présenté en param
const getEvaluationState = (restaurant_id) => {
    const jsonEvaluations = localStorage.getItem('evaluations');
    return JSON.parse(jsonEvaluations);
}
const setEvaluationState = (instance_type_evaluable_id, evaluation_state) => {
    const jsonEvaluations = localStorage.getItem('evaluations') ?? JSON.stringify({});
    let aEvaluations = JSON.parse(jsonEvaluations);
    let stored_evaluation_state = aEvaluations[`instance_type_evaluable_id_${instance_type_evaluable_id}`] ?? {};
    aEvaluations[`instance_type_evaluable_id_${instance_type_evaluable_id}`] = {...stored_evaluation_state, ...evaluation_state};
    localStorage.setItem('evaluations', JSON.stringify(aEvaluations));
}

const addSynchronizationTask = ( callback_function, parameters ) => {
    let jsonQueue = localStorage.getItem('synchronization_queue');
    let aQueue = JSON.parse(jsonQueue);
    aQueue.push({callback: callback_function, parameters: parameters });
    localStorage.setItem('synchronization_queue', JSON.stringify(aQueue));
}

const setThemeValue = (value) => {
    new StorageService().__set_key_value('theme_value',value);
}

const getThemeValue = (default_value) => {
    const val = new StorageService().__get_key('theme_value');
    return !!val;
}



export {
    setAnonymousUserEmail,
    setAnonymousUserPassword,
    getAnonymousUserEmail,
    getAnonymousUserPassword,
    getAnonymousUserToken,
    setAnonymousUserToken,
    getAnonymousRefreshToken,
    setAnonymousRefreshToken,

    setEvenementId,
    getEvenementId,

    getIdentifiedUserToken,
    setIdentifiedUserToken,
    getIdentifiedRefreshToken,
    setIdentifiedRefreshToken,
    removeIdentifiedUserToken,
    removeIdentifierRefreshToken,

    getEvaluationState,
    setEvaluationState,
    addSynchronizationTask,

    setThemeValue,
    getThemeValue,

    getRedirectionUrl,
    setRedirectionUrl,
    removeRedirectionUrl,
    isRedirectionUrlSet,

    setFlashMessage,
    setFlashStyle,
    getFlashMessage,
    getFlashStyle,
    removeFlashMessage,
    isFlashMessageSet,

    setDTOStack,
    getDTOStack,
    removeDTOStack,
};