import {IMAGE_URL} from "../../../Config";
import {SwiperComponent} from "../../../Bibliotheque/Composants/Presentation/SwiperComponent";

const SwiperFactory = (parameters) => {
    const {key,slides,projection_data} = parameters;

    if ( undefined === projection_data.gallery ){
        return (<div key={key}>Swipper without gallery</div>);
    }

    const slide_paths = slides.map( (index) => `${IMAGE_URL}/${projection_data.gallery[index].path}` );

    return (<SwiperComponent key={key} slides_src={slide_paths} />)
}

export {SwiperFactory}