// Theme/scrprod/Page/Connexion.js
// Section principale de la page de connexion
import {Logo} from "./Composants/Logo";
import {FormulaireDeConnexion} from "./Composants/FormulaireDeConnexion";

const ConnexionScreen = () => {

    return (
        <div className={`page-connexion flex flex-col h-full items-center justify-evenly grow`}>
            <Logo />
            <FormulaireDeConnexion />
        </div>
    );
};

export { ConnexionScreen };