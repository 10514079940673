import video_src from "../../Assets/Videos/20240927_festival_sxm_2024.mp4";
import {Video} from "../../Components/Video";

import '../../Assets/scss/_effets.scss';
import {useTranslation} from "react-i18next";

const PageFinDeFestival = () => {

    const {t, i18n} = useTranslation();
    let current_query_string = () => {
        let url = new URL(window.location.href);
        let tags = url.searchParams.getAll("tag");
        if (tags && tags.length) {
            console.log(tags);
        }
    }

    return (
        <div className={`flex flex-col items-center justify-center w-full mx-auto space-y-10`}>
            <Video className={`text-4xl`} videoSrc={video_src}/>
            <h1 className={`text-4xl font-bold text-primary-content px-2 xl:px-0`}>{t('Accueil.FinFestival.titre')}</h1>
            <div className={`text-2xl font-semibold px-2 xl:px-0`}>{t('Accueil.FinFestival.p1')}</div>
            <div className={`text-2xl font-semibold px-2 xl:px-0`}>{t('Accueil.FinFestival.p2')}</div>
        </div>
    );

}

export {
    PageFinDeFestival
};