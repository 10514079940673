import {useTranslation} from "react-i18next";

const EtiquetteDate = ({date}) => {
    const { t, i18n }  = useTranslation();
    const lng = i18n.language;

    const options_jour = {day: "numeric", month: "short", year: "numeric"};
    const options_horaire = {hour: "numeric", minute:"numeric"};

    const jour = date ? Intl.DateTimeFormat(lng, options_jour).format(date) : "";
    const horaire = date ? Intl.DateTimeFormat(lng, options_horaire).format(date) : "";

    return (
        <div className={`flex pr-4`}>
            <div
                className={`bg-black text-white font-medium capitalize px-2 py-4 rounded-b-lg flex flex-col gap-2`}>
                <span>{jour}</span>
                <span>{horaire}</span>
            </div>
        </div>
    );
};

export {EtiquetteDate}