import * as React from "react";
import * as ReactDOM from "react-dom/client";

import "./css/tailwindbasis.css";
import './i18n/i18n';

import './Theme/festivalSxm2024/Assets/Polices/fonts.css';

import registerServiceWorker from "./Services/sw.init.service";

import {RouterProvider} from "react-router-dom";

import {Container} from "./Container";

import {Suspense} from "react";
import LoadingComponent from "./Components/Elements/loading.component";

const root = ReactDOM.createRoot(document.getElementById("root"));

// initialisation

registerServiceWorker();

const Router = (new Container()).getRouter();

root.render(
    <React.StrictMode>
        <Suspense fallback={<LoadingComponent />}>
            <RouterProvider router={Router} />
        </Suspense>
    </React.StrictMode>
);
