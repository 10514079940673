import {Carte as CarteComponent} from '../../../../Bibliotheque/Composants/Presentation/Carte';
import {useLoaderData} from "react-router-dom";

const Carte = (props) => {

    const {nom, lat, lon} = useLoaderData();

    const position = [lat,lon];

    const oneLineClass = "col-span-1 sm:col-span-2 lg:col-span-3 2xl:col-span-4";

    const formatted_nom = decodeURIComponent(nom);

    const url = new URL(window.location.href);
    let adresse = url.searchParams.get("adresse");
    adresse = decodeURIComponent(adresse);

    return (
        <div
            className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 max-w-[2048px] mx-auto mt-24 mb-12 px-6 w-full">
            <h1 className={`${oneLineClass} flex flex-col`}>
                <span className={`text-2xl text-primary-content font-extrabold`}>{formatted_nom}</span>
            </h1>
            <div className={`${oneLineClass} flex flex-col`}>
                <span className={`text-lg text-primary-content font-semibold`}>{adresse}</span>
            </div>
            <CarteComponent position={position} className={`${oneLineClass}`}/>
        </div>

    );
}

export {Carte};