import {StartEvaluationFactory} from "./Metier/StartEvaluationFactory";
import {BannerFactory} from "./Global/BannerFactory";
import {DateFactory} from "./Global/DateFactory";
import {CarteFactory} from "./Global/CarteFactory";
import {BoutonFactory} from "./Global/BoutonFactory";
import {ParagrapheFactory} from "./Global/ParagrapheFactory";
import {TitreFactory} from "./Global/TitreFactory";

// Redéfinition du modèle utilisé pour l'affichage des évaluations
// on a besoin que les questions soient représentées avec une jauge star rate
const OverridingMapping = {
    StartEvaluation: StartEvaluationFactory,
    Banner: BannerFactory,
    DateComponent: DateFactory,
    Carte: CarteFactory,
    Bouton: BoutonFactory,
    Paragraphe: ParagrapheFactory,
    Titre: TitreFactory,
}

export { OverridingMapping };