import {IMAGE_URL} from "../../../Config";
import {Image} from "../../../Bibliotheque/Composants/Presentation/Image";

const ImageFactory = (parameters) => {
    const {src, projection_data, portrait, key} = parameters;


    const solvegalleryparameter = (src,projection_data) => {
        if (!src){
            return '';
        }
        const matches = src.match(new RegExp("_gallery_\\[(\\d+)\\]"));
        if (matches && matches[1]){
            return `${IMAGE_URL}/${projection_data?.gallery[matches[1]].path}`;
        }
        return src;
    }

    return (
        <Image key={key} src={solvegalleryparameter(src,projection_data)} is_portrait={portrait}/>
    )
}

export {ImageFactory}