const TimelineItemIcon = ({variation}) => {
    return variation === 'plein' ? (<svg className={`w-4 fill-accent`} xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512">
        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/>
    </svg>) : (<svg className={`w-4 md:w-3 fill-accent`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
        <path
            d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/>
    </svg>);
}

const TimelineItemCell = ({direction, children, className}) => {
    return direction === 'start' ?
        (<div className={`timeline-start md:text-end ${className}`}>
            {children}
        </div>) :
        (<div className={`timeline-end md:text-end ${className}`}>
            {children}
        </div>
        );
};

const TimelineItemDate = ({children}) => {
    return (
        children ? <time className="font-mono italic text-md">{children}</time> : <></>
    );
}

const TimelineItemDescription = ({direction, children}) => {
    return (<div className={`text-${direction === 'start' ? 'end' : 'start'}`}>{children}</div>);
}

const TimelineItemCellLayout = ({direction, layout_classes, item_date, children}) => {

    return (
        <div className={`${layout_classes}`}>
            <TimelineItemDate>{item_date}</TimelineItemDate>
            <TimelineItemDescription direction={direction}>{children}</TimelineItemDescription>
        </div>
    );
}

const TimelineItem = ({direction, variation, item_date, children, item_index, n_items}) => {

    const layout_classes = () => {
        let classes_to_return = ' flex ';
        if (variation === 'plein'){
            classes_to_return += 'flex-col';
            return classes_to_return;
        }
        if (direction === 'start'){
            classes_to_return += 'flex-row-reverse gap-2';
        }else{
            classes_to_return += 'flex-row gap-2';
        }
        return classes_to_return;
    }

    return (
        <li>
            {item_index !== 0 ? (<hr />) : (<></>)}
            <div className="timeline-middle">
                <TimelineItemIcon variation={variation} />
            </div>
            <TimelineItemCell direction={direction} className={`${variation==='plein' ? 'text-lg' : 'text-sm'}`}>
                <TimelineItemCellLayout direction={direction} item_date={item_date} layout_classes={layout_classes()}>
                    {children}
                </TimelineItemCellLayout>
            </TimelineItemCell>
            {item_index === (n_items-1) ? (<></>) : (<hr/>)}
        </li>
    );
};

export { TimelineItem };