import '../../Assets/scss/_effets.scss';
import {useTranslation} from "react-i18next";
import {TimeService} from "../../../../Services/time.service";

const SingleDayDisplay = ({day_string, start_time_string, end_time_string}) => {

    const {t} = useTranslation();

    return (
        <>
            <span
                className={`px-10 max-w-3xl text-lg font-serif italic bg-accent text-accent-content font-light tracking-wider clip-path-parallelogram`}
            >
                {day_string}
            </span>
            <span className={`text-md font-serif italic text-primary-content font-light tracking-wide`}>
                {t('Components.DateComponent.starttimetoendtime', {heure_debut: start_time_string, heure_fin: end_time_string})}
            </span>
        </>
);
}

const MultipleDayDisplay = (start_day_string, end_day_string, start_time_string, end_time_string) => {
    return (<></>);
}

const DateComponent = ({date_debut, date_fin}) => {

    const {i18n} = useTranslation();

    const day_formatter = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }

    let day = null;

    if (date_debut.getDate() === date_fin.getDate()) {
        // on assume que l'intervalle n'est pas un multiple d'un mois
        // => on est le même jour
        day = new TimeService().convertDateToLocaleDateString(date_debut,i18n.language);
    }

    let start_time_string = new TimeService().convertDateToLocaleDateTimeString(date_debut, i18n.language);
    let end_time_string = new TimeService().convertDateToLocaleDateTimeString(date_fin, i18n.language);

    return (
        <div className={`flex flex-col text-center m-auto items-center gap-2`}>
            { day ? (<SingleDayDisplay day_string={day} start_time_string={start_time_string} end_time_string={end_time_string} />) :
                (<MultipleDayDisplay />)
            }
        </div>
    )
}

export {DateComponent}