// Theme/scrprod/Page/Connexion/Composants/Logo.js
//

import {useTranslation} from "react-i18next";
import image_logo from "../../../Assets/Image/20241003_logo_festival_gastronomique.png";

const Logo = () => {
    const { t } = useTranslation();

    return (
        <figure className={`logo-container py-8 px-16 rounded-2xl bg-white`}>
            <img src={image_logo} alt={`${t('Login.logo.alt')}`} className={``}/>
        </figure>
    );
};

export { Logo };