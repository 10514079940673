import i18n from "i18next";
import {transformDate} from "./generic.helpers";

class TimeService {
    convertDateTimeStringToJSDateTimeObjet(datetime_string)
    {
        const _date = transformDate(datetime_string);
        return _date;
    }

    convertDateTimeStringToLocaleDateString(datetimestring, locale)
    {
        const _date = this.convertDateTimeStringToJSDateTimeObjet(datetimestring);
        return this.convertDateToLocaleDateString(_date,locale);

    }

    convertDateToLocaleDateString(date, locale)
    {
        const day_formatter = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }

        return (new Intl.DateTimeFormat(locale, day_formatter)).format(date);

    }

    convertDateToLocaleDateTimeString(date,locale)
    {

        const time_formatter = {
            hour: "numeric",
            minute: "numeric"
        };

        return (new Intl.DateTimeFormat(locale, time_formatter)).format( date );
    }
    convertDateTimeStringToLocaleDateTimeString(datetimestring, locale)
    {
        const _date = this.convertDateTimeStringToJSDateTimeObjet(datetimestring);
        return this.convertDateToLocaleDateTimeString(_date,locale);
    }

}

export {TimeService}