import {Await, useLoaderData} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Suspense} from "react";
import LoadingComponent from "../../Elements/loading.component";
import {SplashImage} from "../../../Bibliotheque/Composants/Metier/SplashImage";
import {CarteCompetiteur} from "../../../Bibliotheque/Composants/Metier/CarteCompetiteur";

const ListeInstanceFactory = (props) => {

    const {key} = props;
    const loaded = useLoaderData();
    const { t, i18n }  = useTranslation();

    const lng = i18n.language;

    const displayNomQuestionnaire = (data) => {

        const displayedNom = data["0"]?.questionnaire?.translatedNom[lng];

        return displayedNom ?
            (<div className={``}>{`${displayedNom}`}</div>) :
            (<>Questionnaire</>)
            ;
    }

    const displayListeCandidats = (data) => {

        let evaluations = [];
        for ( const [clef, valeur] of Object.entries(data)){
            let k = parseInt(clef);
            if ( ! Number.isInteger(k) ){
                continue;
            }
            evaluations[k] = valeur;
        }

        return (<div className={`flex flex-col gap-8 items-center`}>{evaluations.map(
            (e,i) =>
                <CarteCompetiteur key={i} evaluation={e}/>
        )}</div>);
    }

    return (
        <Suspense fallback={<LoadingComponent/>} key={key}>
            <Await resolve={loaded.data}>
                { loaded?.data?.projection?.gallery[0]?.path ? (<SplashImage image_path={loaded.data.projection.gallery[0].path}/>) : (<></>) }
                {/*{ c'est un tableau qui a été transformé en object, json_encode from PHP }*/}
                <section className={`w-full max-w-3xl px-4`}>
                    <h1 className={`text-3xl text-primary-content font-extrabold center mx-auto my-8`}>{displayNomQuestionnaire(loaded?.data)}</h1>
                    <h2 className={`text-2xl text-primary-content font-semibold my-6`}>{`${loaded?.data["0"]?.questionnaire?.type_evaluable?.translatedNom[i18n.language]}`}</h2>
                    { displayListeCandidats(loaded.data) }
                </section>
            </Await>
        </Suspense>
    )
};

export {ListeInstanceFactory}