import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BackButton from "../../Elements/back.button.component";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {iconMarker} from "../../Elements/marker.icon.component";
import '../../../css/Carte.css';
import {getIdentifiedRefreshToken, getIdentifiedUserToken} from "../../../Services/storage.service";
const BoutonAccesQuestionnaireMapping = ({text, liens, cible, key}) => {

    const target_att = cible ? `target="${cible}"` : '';

    const solveurDeLiens = (liens) => {
        let lien = getIdentifiedUserToken() ? liens?.prive : liens?.publique;
        return lien;
    }
    return (
        <div key={key} className={`flex w-full max-w-3xl flex justify-start`}>
            <a className={``} href={solveurDeLiens(liens)}  >
                <button className={`btn btn-xs btn-primary`}>{text}</button>
            </a>
        </div>
    );
}

export {BoutonAccesQuestionnaireMapping};